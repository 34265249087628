import {
  DocxException,
  createParagraph,
  createCombinationParagraph,
  createCombinationParagraphWithSpace,
  createItalicParagraphWithSpace,
  createContentParagraph,
  createEmptyLine,
  SUPERSCRIPT_TEXT,
  BOLD_TEXT,
  UNDERLINED_BOLD_TEXT,
} from '../docxFunctions'

import { createMnnTable } from '../allTables/createMnnTable'
import { AVB, createExpTable, KEI } from '../allTables/createExpTable'
import { EX_GET_CORRESPOND } from '../../expAvbKei/const'
import { createParagraphWithCenter } from '@/components/report/docxFunctions'
import { AlignmentType } from 'docx'
/**
 Создания параграфа-аннотаций к таблице АВБ.
 */
function createAvbAnnotationParagraph() {
  return [
    createItalicParagraphWithSpace('Примечание: '),
    createItalicParagraphWithSpace(
      'высокое методологическое качество присваивается при одновременном соответствии всех пунктов требованиям к методологическому качеству;'
    ),
    createItalicParagraphWithSpace(
      'среднее методологическое качество присваивается при несоответствии требованиям к методологическому качеству любого из следующих критериев (пунктов): П.7 Временной горизонт; П.10 Анализ чувствительности; П11. Ссылки на использованные источники информации;'
    ),
    createItalicParagraphWithSpace(
      'низкое методологическое качество присваивается при несоответствии требованиям к методологическому качеству любого из следующих критериев (пунктов): П.1 Представлены результаты отечественного исследования; П.2 Рассматриваемые показания к применению, П.3 Цена на лекарственный препарат; П.4 Расчет прямых медицинских затрат; П.5 Описание математической модели (при наличии); П.6 Представление прямых медицинских затрат при описании результатов; П.8 Характеристики и численность целевой популяции пациентов; П.9 Представление результатов.'
    ),
  ]
}
/**
 Создания параграфа-аннотаций к таблице КЭИ.
 */
function createKeiAnnotationParagraph() {
  return [
    createItalicParagraphWithSpace('Примечание: '),
    createItalicParagraphWithSpace(
      'высокое методологическое качество присваивается при одновременном соответствии всех пунктов требованиям к методологическому качеству;'
    ),
    createItalicParagraphWithSpace(
      'среднее методологическое качество присваивается при несоответствии требованиям к методологическому качеству любого из следующих критериев (пунктов): П.5 Выбор критериев оценки эффективности; П.9 Представление затрат при описании результатов; П.11 Анализ чувствительности; П12. Ссылки на использованные источники информации;'
    ),
    createItalicParagraphWithSpace(
      'низкое методологическое качество присваивается при несоответствии требованиям к методологическому качеству любого из следующих критериев (пунктов): П.1 Представлены результаты отечественного исследования; П.2 Рассматриваемые показания к применению, П.3 Выбор препарата сравнения; П.4 Метод клинико-экономического исследования; П.6 Цена на лекарственный препарат; П.7 Расчет прямых медицинских затрат; П.8 Описание математической модели (при наличии); П.10 Представление результатов.'
    ),
  ]
}

function createExpData(data) {
  return data.map(({ correspond, ...item }) => ({
    ...item,
    correspond: EX_GET_CORRESPOND(correspond),
  }))
}

function createAllExpTables(data, view) {
  const part = view === KEI ? '1' : '2'

  const annotationParagraph =
    view === KEI ? createKeiAnnotationParagraph : createAvbAnnotationParagraph

  const allTables = data.ka?.reduce(
    (arr, ka, i, arrData) => [
      ...arr,
      createParagraph(`4.${part}.${i + 1}`),
      createExpTable(
        createExpData(ka.elements),
        ka.condition,
        ka.mkb10,
        ka.qLevels,
        ka.qWeight || 0,
        view
      ),
      ...(i === 0 ? annotationParagraph() : []),
      ...(i < arrData.length - 1 ? [createEmptyLine()] : []),
    ],
    []
  )
  return allTables
}

/**
 * ВТОРАЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ ЛП, КЕИ, АВБ
 */
export function createTwoChapter(itemsMNN, CR, kei, avb) {
  try {
    const superscriptParagraph = []
    CR.forEach((item, idx) => {
      superscriptParagraph.push(
        createCombinationParagraph([
          {
            type: SUPERSCRIPT_TEXT,
            content: `${CR[idx].tableKey}`,
          },
          {
            content: `Препарат включен в клинические рекомендации,${
              CR[idx].isApprovedNPS ? ' ' : ' не '
            }одобренные Научно-практическим Советом Министерства здравоохранения РФ и${
              CR[idx].isOnRubricator ? ' ' : ' не '
            }размещенные в информационной системе Министерства здравоохранения РФ «Рубрикатор клинических рекомендаций» (Клинические рекомендации «${
              CR[idx].name
            }» (КР ${CR[idx].crNumber ? CR[idx].crNumber : 'не определено'}); ${
              CR[idx].associations ? CR[idx].associations : 'не определено'
            }; ${CR[idx].year ? CR[idx].year + ' г.' : 'не определено'}).`,
            size: 22,
          },
        ])
      )
    })
    return [
      createCombinationParagraphWithSpace([
        {
          content:
            'д) информацию  о лекарственных  препаратах,  включенных  в   перечень жизненно   необходимых  и  важнейших  лекарственных  препаратов,  в  рамках соответствующих   анатомо-терапевтическо-химических   групп,   а   также  о лекарственных препаратах с аналогичными показаниями к применению перечни, и имеющих аналогичные вносимым лекарственным препаратам показания к применению, а также применяющихся в аналогичных клинических ситуациях, с учетом согласно клинических рекомендаций, утвержденных научно-практическим советом Министерства здравоохранения Российской Федерации.',
        },
      ]),
      ...(itemsMNN?.length
        ? [createMnnTable(itemsMNN), ...superscriptParagraph]
        : [
            createCombinationParagraphWithSpace([
              {
                type: UNDERLINED_BOLD_TEXT,
                content:
                  'лекарственные препараты с аналогичными показаниями отсутствуют;',
              },
            ]),
          ]),
      createEmptyLine(),
      createContentParagraph(
        '4. Результаты оценки методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджеты бюджетной системы Российской Федерации (далее - бюджет) включают:'
      ),
      createCombinationParagraphWithSpace([
        {
          content: 'а) Результаты оценки методологического качества ',
        },
        {
          type: BOLD_TEXT,
          content: 'клинико-экономического исследования: ',
        },
      ]),
      ...createAllExpTables(kei, KEI),
      createEmptyLine(),
      createCombinationParagraphWithSpace([
        {
          content:
            'б) Результаты оценки методологического качества исследований с использованием ',
        },
        {
          type: BOLD_TEXT,
          content: 'анализа влияния на бюджет: ',
        },
      ]),
      ...createAllExpTables(avb, AVB),
      createContentParagraph(
        '7. Подписи и расшифровка подписей сотрудников центра экспертизы, осуществляющих оценку методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджет:'
      ),
      createEmptyLine(),
      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        '(Ф.И.О. сотрудника Центра экспертизы, подпись)'
      ),

      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        ' (руководитель Центра экспертизы, ФИО, подпись)'
      ),
      createParagraph('Дата__________', {
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
    ]
  } catch (err) {
    throw new DocxException(2, err.message)
  }
}
