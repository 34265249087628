<template>
  <PresentationDocx />
</template>

<script>
import PresentationDocx from '@/components/reportPresentation/PresentationDocx'
export default {
  name: 'MedicineReportPresent',
  components: { PresentationDocx },
}
</script>
