import {
  AlignmentType,
  Paragraph,
  TextRun,
  MathRun,
  ExternalHyperlink,
  convertMillimetersToTwip,
  TableCell,
} from 'docx'

export class DocxException extends Error {
  constructor(part, message) {
    super(`${part ? '[Раздел ' + part + '] ' : ''}${message}`)
    this.name = 'DocxException'
    this.part = part
  }
}

const DEFAULT_SIZE = 24
export const RED_LINE_INDENT_DEF = {
  indent: {
    firstLine: convertMillimetersToTwip(12.5),
  },
}
export const RED_LINE_INDENT = {
  indent: {
    firstLine: convertMillimetersToTwip(17.5),
  },
}

export const DEFAULT_TABLE_MARGINS = {
  top: convertMillimetersToTwip(0.5),
  bottom: convertMillimetersToTwip(0.5),
  left: convertMillimetersToTwip(1),
  right: convertMillimetersToTwip(1),
}

export const DEFAULT_TEXT = 'defaultText'
export const BOLD_TEXT = 'boldText'
export const UNDERLINED_TEXT = 'underlinedText'
export const UNDERLINED_BOLD_TEXT = 'underlinedBoldText'
export const ITALIC_TEXT = 'italicText'
export const SUPERSCRIPT_TEXT = 'superscriptText'

/**
 * создание массива объектов TextRun с разделением по перенову строки .
 * @param text - текста,
 * @param options - опции текста,
 */
export function createTextRuns(text = '', options = {}) {
  // подчищаем Unicode Character 'START OF TEXT' (U+0002)
  // режем на массив строк по \n
  const NOT_SAFE_IN_XML_1_0 =
    // eslint-disable-next-line no-control-regex
    /[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm

  return String(text)
    .replace(NOT_SAFE_IN_XML_1_0, '')
    .split('\n')
    .map(
      (text, idx) =>
        new TextRun({
          ...options,
          text,
          break: idx > 0 ? 1 : undefined,
        })
    )
}

/**
 * СОЗДАНИЕ ПАРАГРАФА.
 * @param text -ТЕКСТ ПАРАГРАФА,
 * @param options -ОПЦИИ ПАРАГРАФА.,
 * @param textRunOptions -ОПЦИИ ТЕКСТА.,
 */
export function createParagraph(
  text,
  options = {},
  textRunOptions = {},
  size = DEFAULT_SIZE
) {
  const children = createTextRuns(text, { ...textRunOptions, size })

  return new Paragraph({
    ...options,
    children,
  })
}

/**
 * СОЗДАНИЕ ПАРАГРАФОВ С ВОРДОВСКИМ ПЕРЕНОСОМ СТРОКИ.
 * @param text -ТЕКСТ ПАРАГРАФА,
 */
export function createSeparateParagraphs(text, options, textRunOptions, size) {
  return String(text || '')
    .split('\n')
    .map(s => createParagraph(s, options, textRunOptions, size))
}

export function createParagraphTitle(text) {
  return new Paragraph({
    spacing: {
      after: 10,
    },
    alignment: AlignmentType.CENTER,
    children: createTextRuns(text),
  })
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С ВЫРАВНИВАНИЕМ ПО ЦЕНТРУ
 * @param text
 */
export function createParagraphWithCenter(
  text,
  options = {},
  spacing = { after: 200 }
) {
  return new Paragraph({
    alignment: AlignmentType.CENTER,
    spacing,
    children: createTextRuns(text, { ...options }),
  })
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С КРАСНОЙ СТРОКОЙ (ОТСТУПОМ НА ПЕРВОЙ СТРОКЕ)
 * @param {string} text
 * @param {number} size
 */
export function createContentParagraph(
  text,
  options = {},
  textOptions = {},
  redLine = true
) {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    ...(redLine ? RED_LINE_INDENT_DEF : {}),
    ...options,
    children: createTextRuns(text, { size: DEFAULT_SIZE, ...textOptions }),
  })
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С КРАСНОЙ СТРОКОЙ (ОТСТУПОМ НА ПЕРВОЙ СТРОКЕ)
 * @param {string} text
 * @param {number} size
 */
export function createSecondContentParagraph(
  text,
  options = {},
  textOptions = {},
  redLine = true
) {
  return createContentParagraph(
    text,
    { ...(redLine ? RED_LINE_INDENT : {}), ...options },
    textOptions,
    false
  )
}

/**
 * СОЗДАНИЕ ПАРАГРАФА, ВЫДЕЛЕННОГО ЖИРНЫМ ШРИФТОМ.
 * @param text
 * @param options
 */
export function createBoldParagraph(text, options = {}, size = DEFAULT_SIZE) {
  return createContentParagraph(text, options, { bold: true, size }, false)
}

/** Создание гиперссылки */
export function createHyperLink(text, options = {}) {
  const array = String(text).split('\n')
  const children = []
  array.forEach((text, idx) => {
    children.push(
      new ExternalHyperlink({
        children: [
          new TextRun({
            text,
            style: 'Hyperlink',
            size: DEFAULT_SIZE,
            break: idx > 0 ? 1 : undefined,
          }),
        ],
        link: text,
      })
    )
  })
  return new Paragraph({
    ...options,
    children,
  })
}

export function createItalicParagraphWithSpace(text) {
  return createContentParagraph(
    text,
    { alignment: AlignmentType.JUSTIFIED },
    { italics: true },
    true
  )
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С КОМБИНАЦИЕЙ РАЗНЫХ ТИПОВ ТЕКСТА. НА ВХОД
 * ПРИНИМАЕТ МАССИВ ОБЪЕКТОВ, КАЖДЫЙ ОБЪЕКТ ИМЕЕТ ПОЛЕ **"type" И "content"**.
 * ВОЗМОЖНЫЕ ЗНАЧЕНИЯ **type**- DEFAULT_TEXT, BOLD_TEXT, UNDERLINED_TEXT, UNDERLINED_BOLD_TEXT, ITALIC_TEXT, SUPERSCRIPT_TEXT
 * . **content**-кусок текста параграфа(string)
 * @param {Array} textParts
 */
export function createCombinationParagraph(
  textParts,
  options = { alignment: AlignmentType.JUSTIFIED }
) {
  const children = []
  textParts?.forEach(tp => {
    const text = String(tp.content)
    const size = tp.size ?? DEFAULT_SIZE
    const color = tp.color || undefined

    const textOptions = { size, color }

    switch (tp?.type ?? DEFAULT_TEXT) {
      case BOLD_TEXT:
        textOptions.bold = true
        break
      case UNDERLINED_TEXT:
        textOptions.underline = {
          type: 'single',
        }
        break
      case UNDERLINED_BOLD_TEXT:
        textOptions.bold = true
        textOptions.underline = {
          type: 'single',
        }
        break
      case ITALIC_TEXT:
        textOptions.italics = true
        break
      case SUPERSCRIPT_TEXT:
        textOptions.superScript = [new MathRun(`${tp.content}`)]
        delete textOptions.size
        break
    }

    children.push(...createTextRuns(text, textOptions))
  })
  return new Paragraph({
    spacing: {
      after: 0,
      before: 0,
    },
    children,
    ...options,
  })
}

/** Создание специализированного параграфа Ключ: Значение */
export function createKeyValueParagraph(
  key,
  value = '',
  { delimiter, endDot, types, ...options } = {
    delimiter: ': ',
    endDot: true,
    types: {
      key: UNDERLINED_TEXT,
      delimiter: DEFAULT_TEXT,
      value: DEFAULT_TEXT,
    },
  }
) {
  let content = String(value).trim()
  if ((endDot ?? true) && content && !content.endsWith('.')) {
    content += '.'
  }

  return createCombinationParagraph(
    [
      {
        type: types?.key || UNDERLINED_TEXT,
        content: key,
      },
      {
        type: types?.delimiter || DEFAULT_TEXT,
        content: delimiter ?? ': ',
      },
      {
        type: types?.value || DEFAULT_TEXT,
        content,
      },
    ],
    // опции по дефолну растянутый текст по ширине
    {
      alignment: AlignmentType.JUSTIFIED,
      ...options,
    }
  )
}

export function createKeyValueParagraphWithSpace(key, value, options) {
  return createKeyValueParagraph(key, value, {
    ...RED_LINE_INDENT,
    ...options,
  })
}

export function createKeyValueParagraphsWithSpace(
  key,
  value,
  { redLine, ...options } = {}
) {
  const values = String(value).trim().split('\n')
  const paragraphs = []

  paragraphs.push(
    createKeyValueParagraph(key, values[0], {
      ...RED_LINE_INDENT,
      ...options,
    })
  )

  // добиваем если есть остатки параграфа
  for (let i = 1; i < values.length; i++) {
    paragraphs.push(
      createParagraph(values[i], {
        alignment: AlignmentType.JUSTIFIED,
        ...(redLine ? RED_LINE_INDENT : {}),
        ...options,
      })
    )
  }

  return paragraphs
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С КОМБИНАЦИЕЙ РАЗНЫХ ТИПОВ ТЕКСТА С ВЫРАВНИВАНИЕМ ПО ЦЕНТРУ. НА ВХОД
 * ПРИНИМАЕТ МАССИВ ОБЪЕКТОВ, КАЖДЫЙ ОБЪЕКТ ИМЕЕТ ПОЛЕ **"type" И "content"**.
 * ВОЗМОЖНЫЕ ЗНАЧЕНИЯ **type**- DEFAULT_TEXT, BOLD_TEXT, UNDERLINED_TEXT, UNDERLINED_BOLD_TEXT, ITALIC_TEXT, SUPERSCRIPT_TEXT
 * . **content**-кусок текста параграфа(string)
 * @param {Array} textParts
 */
export function createCombinationParagraphWithCenter(
  textParts,
  options = { alignment: AlignmentType.JUSTIFIED }
) {
  return createCombinationParagraph(textParts, {
    ...options,
    alignment: AlignmentType.CENTER,
  })
}

/**
 * СОЗДАНИЕ ПАРАГРАФА С КОМБИНАЦИЕЙ РАЗНЫХ ТИПОВ ТЕКСТА И КРАСНОЙ СТРОКОЙ. НА ВХОД
 * ПРИНИМАЕТ МАССИВ ОБЪЕКТОВ, КАЖДЫЙ ОБЪЕКТ ИМЕЕТ ПОЛЕ **"type" И "content"**.
 * ВОЗМОЖНЫЕ ЗНАЧЕНИЯ **type**- DEFAULT_TEXT, BOLD_TEXT, UNDERLINED_TEXT, UNDERLINED_BOLD_TEXT, ITALIC_TEXT, SUPERSCRIPT_TEXT
 * . **content**-кусок текста параграфа(string)
 * @param {Array} textParts
 */
export function createCombinationParagraphWithSpace(
  textParts,
  options = { alignment: AlignmentType.JUSTIFIED }
) {
  return createCombinationParagraph(textParts, {
    ...RED_LINE_INDENT,
    ...options,
  })
}

/** Создание отступа */
export function createEmptyLine() {
  return new Paragraph({
    spacing: {
      after: 100,
    },
    children: [
      new TextRun({
        text: '',
      }),
    ],
  })
}

/** делаем таблицу */

export function createTableCell({ children: content, ...options } = {}) {
  const children = content?.length ? content : [createParagraph('')]
  return new TableCell({ children, ...options })
}
