import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createCombinationParagraphWithCenter,
  createParagraph,
  DEFAULT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
} from '../docxFunctions'

function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [
          createParagraph(row.lf, { alignment: AlignmentType.START }, {}, 22),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.min,
              size: 22,
            },
          ]),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(row.max, { alignment: AlignmentType.START }, {}, 22),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`'<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.lf, { alignment: AlignmentType.CENTER }, 22),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.min, { alignment: AlignmentType.CENTER }, 22),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.max, { alignment: AlignmentType.CENTER }, 22),
      ],
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    lf: 'Лек. форма/ Объем закупок',
    min: 'Минимальный',
    max: 'Максимальный',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createPurchaseTable(tableData) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createPurchaseTable] Ошибка построения таблицы "Данные по закупке": ${err.message}`
    )
  }
}
