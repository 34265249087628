import {
  DocxException,
  createContentParagraph,
  createSecondContentParagraph,
  createEmptyLine,
} from '../docxFunctions'
import { createConsequenceTable } from '../allTables/createConsequenceTable'
import { createScoreTable } from '../allTables/createScoreTable'
import { createAgenciesTable } from '../allTables/createAgenciesTable'

/**
 * ТРЕТЬЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ до таблицы CONSEQUENCE TABLE
 */
export function createThirdChapter(consequence, score, about, agencies) {
  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '5. Результаты изучения дополнительных последствий применения лекарственного препарата включают в себя:'
      ),
      createSecondContentParagraph(
        'а)  сведения   о   государственной   регистрации,  обращении,  объемах государственных  закупок  предлагаемого  лекарственного  препарата, а также лекарственных  препаратов,  включенных  в  перечень жизненно необходимых и важнейших лекарственных препаратов и (или) перечень дорогостоящих лекарственных препаратов, и имеющих аналогичные вносимым лекарственным препаратам показания к назначению, а также применяющихся в аналогичных клинических ситуациях, согласно клиническим рекомендациям, утвержденным научно-практическим советом Министерства здравоохранения Российской:'
      ),
      createEmptyLine(),
      createConsequenceTable(consequence),
      createEmptyLine(),
      createSecondContentParagraph(
        'б) Результаты экспертизы прочих данных по лекарственному препарату:'
      ),
      createEmptyLine(),
      createScoreTable(score),
      createEmptyLine(),
      createSecondContentParagraph(
        'в) сведения о рекомендациях иностранных агентств по оценке медицинских технологий о целесообразности государственного финансирования в иностранном государстве предлагаемого лекарственного препарата'
      ),
      createEmptyLine(),
      createAgenciesTable(about, agencies),
    ]
  } catch (err) {
    throw new DocxException(3, err.message)
  }
}
