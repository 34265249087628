<template>
  <WorkDocx />
</template>

<script>
import WorkDocx from '@/components/report/WorkDocx'
export default {
  name: 'MedicineReport',
  components: { WorkDocx },
}
</script>

<style scoped></style>
