import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  createBoldParagraph,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'

export function createCellsInRow() {
  return [
    createTableCell(),
    createTableCell(),
    createTableCell(),
    createTableCell(),
  ]
}

export function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.criteria, { alignment: AlignmentType.CENTER }),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.order, { alignment: AlignmentType.CENTER }),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.name, { alignment: AlignmentType.CENTER }),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.comment, { alignment: AlignmentType.CENTER }),
      ],
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    criteria: 'Международное непатентованное наименование',
    order: 'Лекарственная форма',
    name: 'Отечественный или иностранный производитель',
    comment:
      'Сведения для подготовки предложений по исключению лекарственных препаратов, а также предложения по коррекции наименований лекарственных препаратов и наименований лекарственных форм лекарственных препаратов',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

export function createSummaryTable() {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        new TableRow({
          children: [...createCellsInRow()],
        }),
        new TableRow({
          children: [...createCellsInRow()],
        }),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createSummaryTable] Ошибка построения таблицы сведений для подготовки предложений по исключению лекарственных препаратов : ${err.message}`
    )
  }
}
