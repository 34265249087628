import {
  DocxException,
  createContentParagraph,
  createEmptyLine,
  createParagraph,
  createParagraphWithCenter,
} from '../docxFunctions'
import { createSummaryTable } from '../allTables/createSummaryTable'
import { AlignmentType } from 'docx'

/**
 * ЧЕТВЁРТАЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ
 */
export function createFourChapter() {
  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '6. Сведения для подготовки предложений коррекции наименований лекарственных препаратов и наименований лекарственных форм в соответствии с государственным реестром лекарственных средств по следующей форме:'
      ),
      createEmptyLine(),
      createSummaryTable(),
      createEmptyLine(),
      createContentParagraph(
        '7. Подписи и расшифровка подписей сотрудников центра экспертизы, осуществляющих оценку методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджет:'
      ),
      createEmptyLine(),
      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        '(Ф.И.О. сотрудника Центра экспертизы, подпись)'
      ),

      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        ' (руководитель Центра экспертизы, ФИО, подпись)'
      ),
      createParagraph('Дата__________', {
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
    ]
  } catch (err) {
    throw new DocxException(4, err.message)
  }
}
