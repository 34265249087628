import {
  DocxException,
  createContentParagraph,
  createSecondContentParagraph,
  createEmptyLine,
} from '../docxFunctions'
import { createConsequenceTable } from '../allTables/createConsequenceTable'
import { createScoreTable } from '../allTables/createScoreTable'
import { createAgenciesTable } from '../allTables/createAgenciesTable'

/**
 * ТРЕТЬЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ до таблицы CONSEQUENCE TABLE
 */
export function createThirdChapter(consequence, score, about, agencies) {
  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '5. Результаты изучения дополнительных последствий применения лекарственного препарата включают в себя:'
      ),
      createSecondContentParagraph(
        'а) сведения о государственной регистрации, обращении, объемах государственных закупок предлагаемого лекарственного препарата, а также лекарственных препаратов, включенных в перечень жизненно необходимых и важнейших лекарственных препаратов, в рамках соответствующих анатомо-терапевтическо-химических групп, имеющих аналогичные показания, по следующей форме:'
      ),
      createEmptyLine(),
      createConsequenceTable(consequence),
      createEmptyLine(),
      createSecondContentParagraph(
        'б) Результаты экспертизы прочих данных по лекарственному препарату по следующей форме:'
      ),
      createEmptyLine(),
      createScoreTable(score),
      createEmptyLine(),
      createSecondContentParagraph(
        'в) Сведения о рекомендациях зарубежных агентств по оценке медицинских технологий о целесообразности государственного финансирования в иностранном государстве предлагаемого лекарственного препарата по следующей форме: '
      ),
      createEmptyLine(),
      createAgenciesTable(about, agencies),
    ]
  } catch (err) {
    throw new DocxException(3, err.message)
  }
}
