import {
  createEmptyLine,
  createParagraph,
  createParagraphWithCenter,
  DEFAULT_TEXT,
  DocxException,
  UNDERLINED_BOLD_TEXT,
  BOLD_TEXT,
} from '../docxFunctions'
import { createCostTable } from '@/components/reportPresentation/allTables/createCostTable'
import {
  createCombinationParagraph,
  ITALIC_TEXT,
} from '@/components/report/docxFunctions'

export function createThreeChapter(data) {
  const tablesGnvlp = []
  const tablesVzn = []

  function createCombination(combination, indication, name, index) {
    const calcOffer = []
    const calcYear = ['Продолжительность терапии в год: ']
    const calcPrice = ['Стоимость ']
    const calcCombine = []

    combination.costs?.forEach(cost => {
      calcOffer.push(
        createParagraph(
          `для препарата ${cost.offerLp || '-пусто-'} ${
            cost.dosageMedicine || '-'
          }, `,
          {},
          {},
          20
        )
      )
      calcYear.push(
        `для препарата ${cost.offerLp || '-пусто-'}: ≈ ${
          cost.durationTherapy || '-'
        }. `
      )
      calcPrice.push(
        `1 мг препарата ${cost.offerLp || '-пусто-'}: ≈ ${
          cost.costOneMg || '-'
        }. `
      )
      calcCombine.push(
        createCombinationParagraph([
          {
            type: BOLD_TEXT,
            content: cost.offerLp ? cost.offerLp + ': ' : '-пусто-',
            size: 20,
          },
          {
            type: DEFAULT_TEXT,
            content: cost.costOnePatient[0].formula || '-',
            size: 20,
          },
        ])
      )
    })

    return [
      createCombinationParagraph([
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `Расчёты: ${
            index === 0 ? '(Стоимость предлагаемого ЛП)' : '(Комбинация)'
          } ${index === 0 ? '' : index}`,
        },
      ]),
      createParagraph(
        `Режим дозирования препарата ${name || '-пусто-'} у ${
          indication || '-'
        } по инструкции (данные, использованные для расчетов) составляет: `,
        {},
        {},
        20
      ),
      ...calcOffer,
      createParagraph(calcYear.join(''), {}, {}, 20),
      createParagraph(calcPrice.join(''), {}, {}, 20),
      createEmptyLine(),
      createParagraph(
        `Расчет стоимости 1 года терапии одного пациента: ${
          combination.combinePrice || ''
        }`,
        {},
        {},
        20
      ),
      ...calcCombine,
      createEmptyLine(),
    ]
  }

  data.indicationsData?.gnvlp?.forEach((table, idx) => {
    if (idx + 1 > data.countIndications?.gnvlp) return

    const footers = []
    const combinationsParagraphs = []
    const item = data.indicationsData?.gnvlpCalculations[idx]
    item.combinations?.forEach((comb, index) => {
      combinationsParagraphs.push(
        ...createCombination(comb, item.indication, item.name, index)
      )
    })

    table.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    tablesGnvlp.push(
      createParagraphWithCenter(
        `${table.valuesIndications.join(', ') || '-'}`,
        {
          size: 28,
          bold: true,
        },
        null
      )
    )
    tablesGnvlp.push(createCostTable(table.tableData, table.headers))
    tablesGnvlp.push(...footers)
    tablesGnvlp.push(createEmptyLine())
    tablesGnvlp.push(...combinationsParagraphs)
    tablesGnvlp.push(createEmptyLine())
  })
  data.indicationsData?.vzn14?.forEach((table, idx) => {
    if (idx + 1 > data.countIndications?.vzn14) return

    const footers = []
    const combinationsParagraphs = []
    const item = data.indicationsData?.vznCalculations[idx]
    item.combinations?.forEach((comb, index) => {
      combinationsParagraphs.push(
        ...createCombination(comb, item.indication, item.name, index)
      )
    })

    table.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    tablesVzn.push(
      createParagraphWithCenter(
        `${table.valuesIndications.join(', ') || '-'}`,
        {
          size: 28,
          bold: true,
        },
        null
      )
    )
    tablesVzn.push(createCostTable(table.tableData, table.headers))
    tablesVzn.push(...footers)
    tablesVzn.push(createEmptyLine())
    tablesVzn.push(...combinationsParagraphs)
    tablesVzn.push(createEmptyLine())
  })
  try {
    return [...tablesGnvlp, ...tablesVzn]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
