import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createCombinationParagraphWithCenter,
  createParagraph,
  DEFAULT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
} from '../docxFunctions'

function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [
          createParagraph(
            row.crScience,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.analogCompare,
              size: 22,
            },
          ]),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.population,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.result,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`'<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(
          row.crScience,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.analogCompare,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.population,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.result,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    crScience: 'Клиническое исследование',
    analogCompare: 'Препарат сравнения',
    population: 'Популяция',
    result: 'Результат',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createEffectsTable(tableData) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createPurchaseTable] Ошибка построения таблицы "Данные по закупке": ${err.message}`
    )
  }
}
