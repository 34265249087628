var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Готовность заключения (КО) ")]),(_vm.loading)?_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})],1):_c('v-card-text',[_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.aboutReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.aboutReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.aboutReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.aboutReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.aboutReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAbout},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpAbout',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1052276846)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.aboutReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.aboutReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-1")]),_c('span',[_vm._v(_vm._s(_vm.getAbout))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpAbout',{attrs:{"value":_vm.data.about,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.analogReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.analogReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.analogReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.analogReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.analogReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAnalog},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('AnalogForLog',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,2758321787)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.analogReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.analogReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-2")]),_c('span',[_vm._v(_vm._s(_vm.getAnalog))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('AnalogForLog',{attrs:{"value":_vm.data.analog,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.costReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.costReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.costReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.costReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.costReady)?_c('LastActionDialog',{attrs:{"value":_vm.objCost},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('CostCalc',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1445828744)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.costReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.costReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-3")]),_c('span',[_vm._v(_vm._s(_vm.getCost))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpAbout',{attrs:{"value":_vm.data.cost,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.otherReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.otherReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.otherReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.otherReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.otherReady)?_c('LastActionDialog',{attrs:{"value":_vm.objOther},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpOther',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,874040583)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.otherReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.otherReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-4")]),_c('span',[_vm._v(_vm._s(_vm.getOther))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpOther',{attrs:{"value":_vm.data.other,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.ageReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.ageReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.ageReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.ageReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.ageReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAge},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpAgency',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,729266356)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.ageReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.ageReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-5")]),_c('span',[_vm._v(_vm._s(_vm.getAge))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpAgency',{attrs:{"value":_vm.data.agencies,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.assessmentReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.assessmentReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.assessmentReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.assessmentReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.assessmentReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAssessment},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpAssessment',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1723365269)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.assessmentReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.assessmentReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-6")]),_c('span',[_vm._v(_vm._s(_vm.getAssessment))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpAssessment',{attrs:{"value":_vm.data.assessment,"readonly":""}})],1)],1)],1),_c('v-divider'),_c('UploadKoReport',{attrs:{"data":_vm.data,"disable-upload":!_vm.readyToReport}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }