<template>
  <v-container fluid>
    <v-progress-linear v-if="loadingWorkDocx" indeterminate />
    <v-container>
      <v-card class="elevation-0">
        <v-card-title>Готовность заключения:</v-card-title>
        <v-card-text v-if="loadingWorkDocx">
          <v-skeleton-loader
            type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
          />
        </v-card-text>
        <v-card-text v-else>
          <v-alert
            border="top"
            class="mt-0"
            icon="mdi-numeric-1"
            dense
            text
            :type="getAlertType(mnn)"
            ><span :inner-html.prop="getMNNCheckText" />
          </v-alert>
          <v-alert
            class="mt-0"
            icon="mdi-numeric-2"
            dense
            text
            :type="getAlertType(ath)"
            ><span :inner-html.prop="getATHCheckText" />
          </v-alert>
          <v-alert
            class="mt-0"
            icon="mdi-numeric-3"
            dense
            text
            :type="getAlertType(lForma)"
            ><span :inner-html.prop="getLFormaCheckText" />
          </v-alert>
          <v-alert
            class="mt-0"
            icon="mdi-numeric-4"
            dense
            text
            :type="getAlertType(reproducedLPText)"
            ><span :inner-html.prop="getReproducedCheckText" />
          </v-alert>
          <!--    ********************ЭКСПЕРТИЗЫ********************   -->
          <v-expansion-panels class="mt-1 mb-1" flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                :color="checkFullExpertise ? '#ecf4eb' : '#fdf2e7'"
                expand-icon="mdi-chevron-down"
              >
                <v-row
                  :style="
                    checkFullExpertise ? 'color: #5d9e52' : 'color: #ec9235'
                  "
                  class="exp_header_text ml-8"
                  ><v-icon
                    :style="
                      checkFullExpertise ? 'color: #5d9e52' : 'color: #ec9235'
                    "
                    class="exp_icon"
                    >mdi-numeric-5</v-icon
                  >экспертизы -
                  <span
                    class="ml-1"
                    :inner-html.prop="
                      beStrong(checkFullExpertise ? 'готовы' : 'не готовы')
                    "
                  />
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkAbout)"
                  :icon="getAlertIcon(checkAbout)"
                >
                  {{ getAboutExp }}
                </v-alert>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkAnalog)"
                  :icon="getAlertIcon(checkAnalog)"
                >
                  {{ getAnalogExp }}
                </v-alert>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkKei)"
                  :icon="getAlertIcon(checkKei)"
                >
                  {{ getKeiExp }}
                </v-alert>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkAvb)"
                  :icon="getAlertIcon(checkAvb)"
                >
                  {{ getAvbExp }}
                </v-alert>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkOther)"
                  :icon="getAlertIcon(checkOther)"
                >
                  {{ getOtherExp }}
                </v-alert>
                <v-alert
                  class="mt-3"
                  dense
                  text
                  :type="getAlertType(checkAgencies)"
                  :icon="getAlertIcon(checkAgencies)"
                >
                  {{ getAgenciesExp }}
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--    **********************ТАБЛИЦА***********************      -->
          <v-alert
            v-if="itemsMNN"
            class="mt-3"
            dense
            text
            icon="mdi-numeric-6"
            :type="getAlertType(itemsMNN)"
          >
            информация о лекарственных препаратах, включенных в перечень
            жизненно необходимых и важнейших лекарственных препаратов, в рамках
            соответствующих анатомо-терапевтическо-химических групп, а также о
            лекарственных препаратах с аналогичными показаниями к применению
            <v-data-table
              :headers="headersMNN"
              :items="itemsMNN"
              hide-default-footer
              no-data-text="Лекарственные препараты с аналогичными показаниями отсутствуют"
              light
            />
          </v-alert>
          <v-alert
            v-else
            class="mt-3"
            dense
            text
            icon="mdi-numeric-6"
            :type="getAlertType(itemsMNN)"
          >
            информация об аналогах и лекарственных препаратах, включенных в
            перечень жизненно необходимых и важнейших лекарственных препаратов
            отсутствует!
          </v-alert>
          <v-alert
            class="mt-3"
            dense
            text
            border="bottom"
            icon="mdi-numeric-7"
            :type="getAlertType(signer)"
            ><span :inner-html.prop="getSignerCheckText" />
          </v-alert>
          <v-divider />
          <v-card-actions class="text-right">
            <v-btn
              color="primary"
              :loading="downloadDocx"
              :disabled="!getProperties"
              @click="exportDocx"
              ><v-icon left> mdi-download </v-icon>
              Выгрузить заключение КО (.docx)
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  PageOrientation,
  convertMillimetersToTwip,
  NumberFormat,
  TextRun,
  PageNumber,
  Footer,
} from 'docx'
import { saveAs } from 'file-saver'

import { createOneChapter } from './allChapter/oneChapter'
import { createTwoChapter } from './allChapter/twoChapter'
import { createThirdChapter } from './allChapter/thirdChapter'
import { createFourChapter } from './allChapter/fourChapter'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WorkDocx',
  data: () => ({
    loadingWorkDocx: false,
    downloadDocx: false,

    expertise: null,
    itemsMNN: [],
    analogMap: {},

    headersMNN: [
      {
        text: 'АТХ',
        value: 'ath',
        width: '75px',
        sortable: false,
        cellClass: 'text--disabled',
        filterable: false,
      },
      {
        text: 'МНН',
        value: 'mnn',
        width: '250px',
        sortable: false,
        cellClass: 'text--primary',
      },
      {
        text: 'Лекарственная форма',
        value: 'lForma',
        sortable: false,
        cellClass: 'text--secondary',
      },
    ],
  }),
  computed: {
    ...mapGetters(['storedPlpSigner', 'storedPlp', 'storedPlpId']),

    getAboutExp() {
      return this.checkAbout
        ? 'информация о препарате сформирована'
        : 'информация о препарате не сформирована!'
    },
    getAgenciesExp() {
      return this.checkAgencies
        ? 'экспертиза агенств проведена'
        : 'экспертиза агенств не готова!'
    },
    getAnalogExp() {
      return this.checkAnalog
        ? 'информация об аналогах готова'
        : 'информация об аналогах не готова!'
    },
    getAvbExp() {
      return this.checkAvb
        ? 'экспертиза АВБ проведена'
        : 'экспертиза АВБ не готова!'
    },
    getKeiExp() {
      return this.checkKei
        ? 'экспертиза КЕИ проведена'
        : 'экспертиза КЕИ не готова!'
    },
    getOtherExp() {
      return this.checkOther
        ? 'прочие данные готовы'
        : 'прочие данные не готовы!'
    },
    checkAbout() {
      return !!this.expertise?.about
    },
    checkAgencies() {
      return !!this.expertise?.agencies?.data
    },
    checkAnalog() {
      return !!this.expertise?.analog?.AL
    },
    checkAvb() {
      return !!this.expertise?.avb?.ka
    },
    checkKei() {
      return !!this.expertise?.kei?.ka
    },
    checkOther() {
      return !!this.expertise?.other
    },
    checkFullExpertise() {
      return (
        this.checkAbout &&
        this.checkAgencies &&
        this.checkAnalog &&
        this.checkAvb &&
        this.checkKei &&
        this.checkOther
      )
    },
    mnn() {
      return this.expertise?.about?.mnn
    },
    getMNNCheckText() {
      return `${
        this.expertise?.about?.nameType ??
        'международное непатентованное наименование'
      }:<br /> ${this.beStrong(this.mnn ?? 'ОТСУТСТВУЕТ!')}`
    },
    ath() {
      return this.expertise?.about?.ath
    },
    getATHCheckText() {
      return `атх: ${this.beStrong(this.ath ?? 'ОТСУТСТВУЕТ!')}</b>`
    },
    signer() {
      return this.storedPlpSigner?.fio ?? null
    },
    signerPosition() {
      return this.storedPlpSigner?.position ?? null
    },
    getSignerCheckText() {
      return this.signer
        ? `подписант: ${this.beStrong(this.signer)}${
            this.signerPosition ? ', ' + this.beStrong(this.signerPosition) : ''
          }`
        : `ВНИМАНИЕ! ${this.beStrong('подписант не назначен!')}`
    },
    lForma() {
      return this.expertise?.about?.lForma
    },
    getLFormaCheckText() {
      return this.lForma
        ? `предлагаемые к включению или исключению лекарственные формы зарегистрированного лекарственного препарата:<br />${this.beStrong(
            this.lForma
          )}`
        : `лекарственная форма: ${this.beStrong('ОТСУТСТВУЕТ!')}`
    },
    reproducedLP() {
      return !!this.expertise?.about?.reproducedLP
    },
    reproducedLPText() {
      return this.expertise?.about?.reproducedLPtext
    },
    getReproducedCheckText() {
      return `информация о воспроизведенных или биоаналоговых (биоподобных) лекарственных препаратах:<br />${this.beStrong(
        this.reproducedLPText ?? 'ОТСУТСТВУЕТ!'
      )}`
    },
    //Свойство, проверяющее каждое свойство отчета на его наличие
    getProperties() {
      return (
        this.signer &&
        this.mnn &&
        this.ath &&
        this.lForma &&
        this.reproducedLPText &&
        this.checkFullExpertise &&
        this.itemsMNN
      )
    },
    // аналоги без основного ЛП (сортировка по МНН - ЛФ)
    getAnalogMNN() {
      return (
        this.expertise?.analog?.AL?.slice(1).filter(({ anal }) => anal) ?? []
      ).sort(
        (a, b) =>
          a?.mnn?.localeCompare(b?.mnn) || a?.lForma?.localeCompare(b?.lForma)
      )
    },
    // основной + аналоги
    getListMNN() {
      if (!this.expertise?.analog?.AL?.[0]) return []
      return [this.expertise.analog.AL[0], ...this.getAnalogMNN]
    },
  },
  mounted() {
    this.loadReportData()
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT', 'CREATE_ACTION_LOG']),
    ...mapMutations(['SET_ERROR']),

    async loadReportData() {
      this.loadingWorkDocx = true
      try {
        const { expertise } = await this.GET_PLP_REPORT()

        this.expertise = expertise || {}
        const { AL, CR } = expertise?.analog || {}
        this.analogMap = this.prepareAnalogMap(AL, CR)
        this.itemsMNN = this.createAnalogData(AL)
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка загрузки данных документа.',
          err,
        })
      } finally {
        this.loadingWorkDocx = false
      }
    },
    getAlertType(value) {
      return value ? 'success' : 'warning'
    },
    getAlertIcon(value) {
      return value ? 'mdi-check' : undefined
    },
    beStrong(value) {
      return `<span class="font-weight-medium">${value ?? ''}</span>`
    },
    createAnalogData(analogData) {
      if (!analogData) return []
      // функция для объединения лекформ с одинаковым МНН для таблицы и финального отчёта
      const map = new Map()
      this.getAnalogMNN.forEach(({ mnn, ath, lForma, CR }) => {
        const key = `${ath}:${mnn}`
        const prev = map.get(key) ?? {}
        // собираем аггрегированое значение лекформы
        lForma = prev.lForma ? `${prev.lForma};\n${lForma}` : lForma

        // собираем ссылки на КР
        const linkSet = new Set(prev.link)

        CR?.forEach(({ use }, index) => {
          if (use) linkSet.add(this.analogMap[index])
        })

        map.set(key, { mnn, ath, lForma, link: [...linkSet.values()].sort() })
      })

      return [...map.values()]
    },
    createCRData(CR) {
      const usingCR = []
      CR.forEach((item, index) => {
        const num = this.analogMap[index]
        if (num) usingCR.push({ tableKey: num, ...item })
      })
      return usingCR
    },
    // делаем карту ссылок аналогов на КР
    prepareAnalogMap(AL, CR) {
      let counter = 1
      const outMap = {}
      const filterAL = this.getAnalogMNN
      CR?.forEach((_, index) => {
        if (filterAL?.findIndex(({ CR }) => CR[index].use) > -1) {
          outMap[index] = counter++
        }
      })
      return outMap
    },
    exportDocx() {
      this.downloadDocx = true
      try {
        const doc = new Document({
          sections: [
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(210),
                    width: convertMillimetersToTwip(297),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(20), //2 сантиметра
                    bottom: convertMillimetersToTwip(15), //1.5 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [
                ...createOneChapter(
                  this.signer,
                  this.mnn,
                  this.ath,
                  this.lForma,
                  this.reproducedLP,
                  this.reproducedLPText,
                  !!this.expertise?.about?.gnvlp,
                  !!this.expertise?.about?.vzn14,
                  this.signerPosition,
                  this.expertise?.about?.nameType
                ),
                ...createTwoChapter(
                  this.itemsMNN,
                  this.createCRData(this.expertise?.analog?.CR),
                  this.expertise?.kei,
                  this.expertise?.avb
                ),
                ...createThirdChapter(
                  this.getListMNN,
                  this.expertise?.other,
                  this.expertise?.about,
                  this.expertise?.agencies
                ),
                ...createFourChapter(),
              ],
            },
          ],
        })
        Packer.toBlob(doc).then(blob => {
          //ЗАПИСЫВАЕМ В ЛОГ ВЫГРУЗКУ ОТЧЁТА
          this.CREATE_ACTION_LOG({
            code: 'PLP_REPORT_SAVE',
            message: `Сгенерировано заключение КО для "${this.mnn}"`,
            data: {
              plp_id: this.storedPlpId,
            },
          })
          saveAs(
            blob,
            `Заключение КО для ${this.mnn} от ${this.getTimestamp()}`
          )
        })

        return doc
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка формирования документа',
          err,
        })
        throw err
      } finally {
        this.downloadDocx = false
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>

<style scoped>
.exp_icon {
  position: absolute;
  left: -40px;
  top: -5px;
  /*color: #5d9e52;*/
}
.exp_header_text {
  /*color: #5d9e52;*/
  position: absolute;
}
</style>
