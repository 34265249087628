import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createCombinationParagraphWithCenter,
  createParagraph,
  DEFAULT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
} from '../docxFunctions'

function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [
          createParagraph(
            row.releaseForm,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.registerPrice
                ? row.registerPrice + ' - без НДС'
                : 'Цена без НДС отсутствует',
              size: 22,
            },
          ]),
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.registerPriceWithNdc
                ? row.registerPriceWithNdc + ' - с НДС'
                : 'Цена с НДС отсутствует',
              size: 22,
            },
          ]),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.pricePurchase
                ? 'Средневзвешен. - ' + row.pricePurchase
                : 'Средневзвешен. -  отсутствует',
              size: 22,
            },
          ]),
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.pricePurchaseLast
                ? 'Последняя - ' +
                  row.pricePurchaseLast +
                  ` (${row.dateLastContract || '*Дата отсутствует*'})`
                : 'Последняя -  отсутствует',
              size: 22,
            },
          ]),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.retailPrice,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`'<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(
          row.releaseForm,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.registerPrice,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.pricePurchase,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.retailPrice,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    releaseForm: 'Форма выпуска/ цена',
    registerPrice: 'План. к рег-ции, руб.',
    pricePurchase: 'Гос. закупок\n' + '(НДС + надб.), руб.',
    retailPrice: 'Розничная цена\n' + '(НДС + надб.), руб.',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createCharacteristics(tableData) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createMnnTable] Ошибка построения таблицы "Данные по закупке": ${err.message}`
    )
  }
}
