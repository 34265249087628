<template>
  <v-container fluid>
    <v-container>
      <v-card elevation="0">
        <v-card-title>
          Готовность заключения (Сводное заключение)
        </v-card-title>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="list-item-two-line" />
        </v-card-text>
        <v-card-text v-else>
          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!conclusionReady">
              <v-expansion-panel-header
                :style="
                  !conclusionReady ? 'cursor: default' : 'cursor: pointer'
                "
                :color="conclusionReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="conclusionReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !conclusionReady"
                    :value="objConclusion"
                  >
                    <template #content="{ value }">
                      <ConclusionReport :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="conclusionReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="
                      conclusionReady ? 'color: #5d9e52' : 'color: #ec9235'
                    "
                    >mdi-numeric-1</v-icon
                  >
                  <span>{{ getConclusion }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ConclusionReport :value="data.conclusion" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!otherReady">
              <v-expansion-panel-header
                :style="!otherReady ? 'cursor: default' : 'cursor: pointer'"
                :color="otherReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="otherReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !otherReady"
                    :value="objOther"
                  >
                    <template #content="{ value }">
                      <ExpOther :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="otherReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="otherReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-2</v-icon
                  >
                  <span>{{ getOther }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpOther :value="data.other" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <UploadConclusionReport
            :data="data"
            :disable-upload="!readyToReport"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConclusionReport from '@/components/expConclusion/ConclusionReport'
import UploadConclusionReport from '@/components/expConclusion/UploadConclusionReport'
import { SUB_EXPERTISE_FOR_CONCLUSION_REPORT } from '@/store/const/expertise'
import LastActionDialog from '@/components/viewLogs/LastActionDialog'
import ExpOther from '@/components/expOther/ExpOther.vue'

export default {
  name: 'MedicineReportConclusion',
  components: {
    LastActionDialog,
    ConclusionReport,
    UploadConclusionReport,
    ExpOther,
  },

  data: () => {
    return {
      data: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'plp_task_id',
      'versionListReady',
      'isManager',
      'storedPlpId',
    ]),
    readyToReport() {
      return this.conclusionReady || this.otherReady
    },
    conclusionReady() {
      return !!this.data?.conclusion
    },
    otherReady() {
      return !!this.data?.other
    },
    getConclusion() {
      return this.conclusionReady
        ? 'Сводное заключение готово'
        : 'Сводное заключение не готово!'
    },
    getOther() {
      return this.otherReady ? 'Проч. данные готово' : 'Проч. данные не готово!'
    },
    objConclusion() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'conclusion',
      }
    },
    objOther() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'other',
      }
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  async created() {
    this.loading = true
    try {
      const [report] = await Promise.all([
        this.GET_PLP_REPORT(SUB_EXPERTISE_FOR_CONCLUSION_REPORT),
      ])
      this.data = report?.expertise
    } finally {
      this.loading = false
    }
  },
}
</script>
