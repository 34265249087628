import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  createBoldParagraph,
  createParagraph,
  createSeparateParagraphs,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'

export function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [createParagraph(row.groupName.value ?? '-', {}, {}, 20)],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.mnn.value || '',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.patientPopulation.value || '-',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`'<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.kei, { alignment: AlignmentType.CENTER }, 20),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.result,
          { alignment: AlignmentType.CENTER },
          20
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.score, { alignment: AlignmentType.CENTER }, 20),
      ],
    }),
  ]
}

export function createTableHeaderRows() {
  return [
    new TableRow({
      children: createHeaderCellsInRow({
        kei: 'Клинико-экономическое исследование (анализ влияния на бюджет)',
        result: 'Результат оценки (процент отклонения)',
        score: 'Оценка (баллов)',
      }),
    }),
  ]
}

export function createAssessmentTable(tableData) {
  const createText = function (indicators) {
    let text = ''
    indicators.forEach((item, index) => {
      text += `Сценарий ${index + 1}: ${item.percentDeviation}%\n`
    })
    return text
  }
  try {
    return new Table({
      alignment: AlignmentType.JUSTIFIED,
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        ...createTableHeaderRows(),
        new TableRow({
          children: [
            createTableCell({
              children: [
                createParagraph(
                  'Клинико-экономическое исследование',
                  {},
                  {},
                  20
                ),
              ],
            }),
            createTableCell({
              children: [
                createParagraph(
                  createText(tableData.indicatorsKei),
                  {},
                  {},
                  20
                ),
              ],
            }),
            createTableCell({
              children: [createParagraph(tableData?.scoreKei || 0, {}, {}, 20)],
            }),
          ],
        }),
        new TableRow({
          children: [
            createTableCell({
              children: [
                createParagraph('Анализ влияния на бюджет', {}, {}, 20),
              ],
            }),
            createTableCell({
              children: [
                createParagraph(
                  createText(tableData.indicatorsAvb),
                  {},
                  {},
                  20
                ),
              ],
            }),
            createTableCell({
              children: [createParagraph(tableData?.scoreAvb || 0, {}, {}, 20)],
            }),
          ],
        }),
        new TableRow({
          children: [
            createTableCell({
              children: [
                createParagraph(
                  'Итоговая оценка по результатам оценки экономических последствий применения лекарственного препарата по показанию',
                  {},
                  {},
                  20
                ),
              ],
              columnSpan: 2,
            }),
            createTableCell({
              children: [
                createParagraph(tableData?.resultScore || 0, {}, {}, 20),
              ],
            }),
          ],
        }),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createAssessmentTable] Ошибка построения таблицы assessment: ${err.message}`
    )
  }
}

