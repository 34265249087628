import {
  BOLD_TEXT,
  createCombinationParagraphWithSpace,
  createKeyValueParagraphWithSpace,
  createKeyValueParagraphsWithSpace,
  createEmptyLine,
  DEFAULT_TEXT,
  UNDERLINED_BOLD_TEXT,
  UNDERLINED_TEXT,
} from '@/components/report/docxFunctions'
import {
  EX_KEI_METHOD,
  EX_QLEVELS_HI,
  EX_QLEVELS_MID,
} from '@/components/expAvbKei/const'
import { EMPTY_DATA, KEI, AVB } from '@/components/expConclusion/const'

// Экспертиза КЭИ/АВБ по типу с порядковым номером КЭИ-1 / КЭИ-2 или КЕИ, если массив из 1 элемента
const textKeiAvb = (expName = KEI, idx = 0, arr = []) =>
  `${expName}${arr.length > 1 ? `-${idx + 1}` : ''}`

// Подготовка раздела отчёты КЭИ
function createKeiReports(exps = []) {
  const report = []
  exps?.forEach((item, idx, arr) => {
    const methodList = reduceKeiMethodList(item)
    const keiName = textKeiAvb(KEI, idx, arr)

    report.push(
      createKeyValueParagraphWithSpace(`Отчет по ${keiName}`, item.resume, {
        types: {
          key: BOLD_TEXT,
        },
      }),
      ...createKeyValueParagraphsWithSpace(
        `Показание, по которому выполнено ${keiName}`,
        item.condition || EMPTY_DATA
      ),
      // createKeyValueParagraphWithSpace(
      //   `Препараты сравнения`,
      //   getForScenario(item.analogsCompare),
      //   { delimiter: ', ' }
      // ),
      ...getForKeiScenario(item.analogsCompare),
      createKeyValueParagraphWithSpace(
        `В ${keiName} использован метод`,
        methodList
      ),
      ...createCriterias(item, keiName)
    )

    // комментарий если есть
    if (String(item.comment || '').trim().length) {
      report.push(
        createKeyValueParagraphWithSpace(`Комментарии`, item.comment, {
          delimiter: ':\n',
        })
      )
    }
    report.push(createEmptyLine())
  })

  // если нет ни одного КЕИ
  if (!report.length) {
    report.push(
      createKeyValueParagraphWithSpace(
        `Отчет по КЭИ`,
        'заявлен, но не представлен в составе Предложения',
        {
          types: {
            key: BOLD_TEXT,
          },
        }
      )
    )
  }
  return report
}

// Подготовка раздела отчёты АВБ
function createAvbReports(exps = []) {
  const report = []
  exps?.forEach((item, idx, arr) => {
    const avbName = textKeiAvb(AVB, idx, arr)

    report.push(
      createKeyValueParagraphWithSpace(`Отчет по ${avbName}`, item.resume, {
        types: {
          key: BOLD_TEXT,
        },
      }),
      ...createKeyValueParagraphsWithSpace(
        `Показание, по которому выполнен ${avbName}`,
        item.condition || EMPTY_DATA
      ),
      createCombinationParagraphWithSpace([
        {
          type: UNDERLINED_TEXT,
          content: `Текущая и ожидаемая практика согласно ${avbName}: `,
        },
      ]),
      ...getForAvbScenario(item.currentState, item.expectedState),
      ...createCriterias(item, avbName)
    )
    // комментарий к АВБ если есть
    if (String(item?.comment || '').trim().length) {
      report.push(
        createKeyValueParagraphWithSpace(`Комментарии`, item.comment, {
          delimiter: ':\n',
        })
      )
    }
    report.push(createEmptyLine())
  })

  // если нет ни одного АВБ
  if (!report.length) {
    report.push(
      createKeyValueParagraphWithSpace(
        `Отчет по АВБ`,
        'заявлен, но не представлен в составе Предложения',
        {
          types: {
            key: BOLD_TEXT,
          },
        }
      )
    )
  }

  return report
}

export function getForAvbScenario(curState = [], expState = []) {
  const outArr = []

  curState.forEach((item, idx, arr) => {
    // если есть сценарии
    if (arr.length > 1) {
      outArr.push(
        createCombinationParagraphWithSpace([
          {
            type: UNDERLINED_TEXT,
            content: `Сценарий ${idx + 1}:`,
          },
        ])
      )
    }

    outArr.push(
      ...createKeyValueParagraphsWithSpace(
        'Текущая практика лекарственной терапии',
        item,
        { delimiter: ', ', types: { key: DEFAULT_TEXT }, redLine: true }
      ),
      ...createKeyValueParagraphsWithSpace(
        'Ожидаемая практика лекарственной терапии',
        expState[idx],
        { delimiter: ', ', types: { key: DEFAULT_TEXT }, redLine: true }
      )
    )
  })
  return outArr
}

export function getForKeiScenario(analogs = []) {
  const outArr = []

  analogs?.forEach((item, idx, arr) => {
    // если есть сценарии
    if (arr.length > 1) {
      outArr.push(
        createCombinationParagraphWithSpace([
          {
            type: UNDERLINED_TEXT,
            content: `Сценарий ${idx + 1}:`,
          },
        ])
      )
    }

    outArr.push(
      createCombinationParagraphWithSpace([
        {
          type: UNDERLINED_TEXT,
          content: 'Препараты сравнения,',
        },
        {
          type: DEFAULT_TEXT,
          content: ` ${item || ''}`,
        },
      ])
    )
  })
  return outArr
}

////////----------------------------------------//////////
function createCriterias(item, expName) {
  // Среднее и низкое качество
  if (item.qLevels !== EX_QLEVELS_HI) {
    const itemRequirements = []

    item?.criterias?.forEach(criteria => {
      const criteriaPoints = criteria.itemRequirements?.join(', ') || ''
      const isManyPoint = criteria.itemRequirements?.length > 1

      // если там многострочный текст то его нужно новыми параграфами?
      const texts = String(criteria?.description || '')
        .trim()
        .split('\n')

      itemRequirements.push(
        createKeyValueParagraphWithSpace(
          `«${criteria.name}» ${
            isManyPoint ? 'пункты' : 'пункт'
          } ${criteriaPoints} Постановления`,
          texts[0] || EMPTY_DATA,
          {
            types: {
              key: BOLD_TEXT,
            },
            endDot: false,
          }
        )
      )
      // остальные с нового параграфа
      for (let i = 1; i < texts.length; i++) {
        itemRequirements.push(
          createCombinationParagraphWithSpace([{ content: texts[i] }])
        )
      }
      // комментарий если есть для критерия
      if (String(criteria.comment || '').trim().length) {
        itemRequirements.push(
          createKeyValueParagraphWithSpace(`Комментарий`, criteria.comment)
        )
      }
    })

    return [
      createCombinationParagraphWithSpace([
        {
          type: BOLD_TEXT,
          content: `Представленное ${expName} обладает `,
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: item.qLevels === EX_QLEVELS_MID ? 'средним' : 'низким',
        },
        {
          type: BOLD_TEXT,
          content:
            ' методологическим качеством, так как при оценке методологического качества были выявлены несоответствия требованиям Постановления по критериям: ',
        },
      ]),
      ...itemRequirements,
    ]
  } else {
    // высокое качество
    return [
      createCombinationParagraphWithSpace([
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `Методологическое качество ${expName} высокое`,
        },
        {
          type: BOLD_TEXT,
          content: `.`,
        },
      ]),
    ]
  }
}

function reduceKeiMethodList(keiItem) {
  const methods = []
  //по умолчанию аннотации пустые, т. е их нет в массиве аннотаций
  let annotationsEmpty = true
  const annotations = keiItem?.method?.annotations || []
  keiItem?.method?.items?.forEach((check, idx) => {
    if (check) {
      //если есть хотя бы одна аннотация
      if (annotations[idx]) annotationsEmpty = false
      const methodTitle = String(EX_KEI_METHOD[idx]?.title || '')

      // Если нет хотя бы одной аннотаций то не добавляем ее
      if (annotationsEmpty) {
        methods.push(`${methodTitle.toLocaleLowerCase()}`.toLowerCase())
      } else {
        const annotation = annotations[idx] || ''
        methods.push(
          `- ${methodTitle.toLocaleLowerCase()} ${annotation}`.trim()
        )
      }
    }
  })
  return methods.join(`${annotationsEmpty ? ', ' : '\n '}`)
}

export function createReports(exps, expName = KEI) {
  if (!Array.isArray(exps)) return []

  const isKei = expName === KEI
  const createReportMethod = isKei ? createKeiReports : createAvbReports

  return createReportMethod(exps) ?? []
}

// параграф с описанием методологического качества - кратко
export function createShortReports(expertise = [], expName = KEI) {
  const paragraphs = []
  // перебираем все экспертизы
  expertise?.forEach((item, idx, arr) => {
    if (item.qLevels === EX_QLEVELS_HI) {
      // если высокое качество
      paragraphs.push(
        createCombinationParagraphWithSpace([
          {
            type: UNDERLINED_BOLD_TEXT,
            content: `Методологическое качество ${textKeiAvb(
              expName,
              idx,
              arr
            )} высокое`,
          },
          {
            type: BOLD_TEXT,
            content: `.`,
          },
        ])
      )
    } else {
      // иначе низкое или среднее качество
      paragraphs.push(
        createCombinationParagraphWithSpace([
          {
            type: BOLD_TEXT,
            content: `Представленное ${textKeiAvb(
              expName,
              idx,
              arr
            )} обладает `,
          },
          {
            type: UNDERLINED_BOLD_TEXT,
            content: item.qLevels === EX_QLEVELS_MID ? 'средним' : 'низким',
          },
          {
            type: BOLD_TEXT,
            content:
              ' методологическим качеством, так как при оценке методологического качества были выявлены несоответствия требованиям Постановления по критериям.',
          },
        ])
      )
    }
  })
  return paragraphs
}
