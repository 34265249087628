import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createCombinationParagraphWithCenter,
  createParagraph,
  DEFAULT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
  BOLD_TEXT,
} from '../docxFunctions'
import { toMoney } from '@/lib/strUtils'

function createDiffParagraph(regPrice, minPrice, diffPercent) {
  if (regPrice > minPrice)
    return createCombinationParagraphWithCenter([
      {
        type: BOLD_TEXT,
        content: `!! Больше на ${String(diffPercent).replace('.', ',')} %`,
        size: 22,
      },
    ])
  else if (minPrice > regPrice)
    return createCombinationParagraphWithCenter([
      {
        type: DEFAULT_TEXT,
        content: `Меньше на ${String(diffPercent).replace('.', ',')} %`,
        size: 22,
      },
    ])
  else
    return createCombinationParagraphWithCenter([
      {
        type: DEFAULT_TEXT,
        content: `-`,
        size: 22,
      },
    ])
}

function createCellsInRow(row, mnn) {
  const minCountry =
    row.currencyPrice?.find(country => {
      if (country.priceRUB)
        return Number(country.priceRUB) === Number(row.minPrice)
    })?.country || ''
  try {
    return [
      createTableCell({
        children: [
          createParagraph(
            `${mnn}, ${row.releaseForm}`,
            { alignment: AlignmentType.START },
            {},
            22
          ),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content:
                row.registerPrice > 0 ? toMoney(row.registerPrice, true) : '-',
              size: 22,
            },
          ]),
        ],
        verticalAlign: 'center',
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content:
                row.minPrice > 0
                  ? toMoney(row.minPrice, true)
                  : 'Не зарегистрирована',
              size: 22,
            },
          ]),
        ],
        verticalAlign: 'center',
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: minCountry || '-',
              size: 22,
            },
          ]),
        ],
        verticalAlign: 'center',
      }),
      createTableCell({
        children: [
          createDiffParagraph(
            row.registerPrice,
            row.minPrice,
            row.differentPrice
          ),
        ],
        verticalAlign: 'center',
      }),
    ]
  } catch (err) {
    throw new Error(`'<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(
          row.releaseForm,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.registerPrice,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
      verticalAlign: 'center',
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.minPrice,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
      verticalAlign: 'center',
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.minCountry,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
      verticalAlign: 'center',
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.differentPrice,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
      verticalAlign: 'center',
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    releaseForm: 'МНН, форма выпуска',
    registerPrice: 'Цена в Российской Федерации (руб.)',
    minPrice: 'Минимальная цена в референтной стране, руб. ',
    minCountry: 'Референтная страна с минимальной ценой',
    differentPrice: 'Разница с ценой в Референтной стране (разница в %)',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createRefTable(tableData, mnn) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row, mnn)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createRefTable] Ошибка построения таблицы "Референтные страны": ${err.message}`
    )
  }
}
