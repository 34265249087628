<template>
  <v-container fluid>
    <v-container>
      <v-card elevation="0">
        <v-card-title> Готовность заключения (КО) </v-card-title>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="list-item-two-line" />
        </v-card-text>
        <v-card-text v-else>
          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!aboutReady">
              <v-expansion-panel-header
                :style="!aboutReady ? 'cursor: default' : 'cursor: pointer'"
                :color="aboutReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="aboutReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !aboutReady"
                    :value="objAbout"
                  >
                    <template #content="{ value }">
                      <ExpAbout :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="aboutReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="aboutReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-1</v-icon
                  >
                  <span>{{ getAbout }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAbout :value="data.about" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!analogReady">
              <v-expansion-panel-header
                :style="!analogReady ? 'cursor: default' : 'cursor: pointer'"
                :color="analogReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="analogReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !analogReady"
                    :value="objAnalog"
                  >
                    <template #content="{ value }">
                      <AnalogForLog :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="analogReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="analogReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-2</v-icon
                  >
                  <span>{{ getAnalog }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <AnalogForLog :value="data.analog" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!costReady">
              <v-expansion-panel-header
                :style="!costReady ? 'cursor: default' : 'cursor: pointer'"
                :color="costReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="costReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !costReady"
                    :value="objCost"
                  >
                    <template #content="{ value }">
                      <CostCalc :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="costReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="costReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-3</v-icon
                  >
                  <span>{{ getCost }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAbout :value="data.cost" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!otherReady">
              <v-expansion-panel-header
                :style="!otherReady ? 'cursor: default' : 'cursor: pointer'"
                :color="otherReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="otherReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !otherReady"
                    :value="objOther"
                  >
                    <template #content="{ value }">
                      <ExpOther :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="otherReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="otherReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-4</v-icon
                  >
                  <span>{{ getOther }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpOther :value="data.other" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!ageReady">
              <v-expansion-panel-header
                :style="!ageReady ? 'cursor: default' : 'cursor: pointer'"
                :color="ageReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="ageReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !ageReady"
                    :value="objAge"
                  >
                    <template #content="{ value }">
                      <ExpAgency :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="ageReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="ageReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-5</v-icon
                  >
                  <span>{{ getAge }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAgency :value="data.agencies" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!assessmentReady">
              <v-expansion-panel-header
                :style="
                  !assessmentReady ? 'cursor: default' : 'cursor: pointer'
                "
                :color="assessmentReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="assessmentReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !assessmentReady"
                    :value="objAssessment"
                  >
                    <template #content="{ value }">
                      <ExpAssessment :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="assessmentReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="
                      assessmentReady ? 'color: #5d9e52' : 'color: #ec9235'
                    "
                    >mdi-numeric-6</v-icon
                  >
                  <span>{{ getAssessment }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAssessment :value="data.assessment" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <UploadKoReport :data="data" :disable-upload="!readyToReport" />
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SUB_EXPERTISE_FOR_KO_REPORT } from '@/store/const/expertise'
import LastActionDialog from '@/components/viewLogs/LastActionDialog'
import ExpAbout from '@/components/expAbout/ExpAbout.vue'
import AnalogForLog from '@/components/expAnalogs/AnalogForLog.vue'
import CostCalc from '@/components/expCost/CostCalc.vue'
import ExpOther from '@/components/expOther/ExpOther.vue'
import ExpAgency from '@/components/expAgency/ExpAgency'
import UploadKoReport from '@/components/reportKO/UploadKoReport.vue'
import ExpAssessment from '../components/expAssessment/expAssessment.vue'

export default {
  name: 'MedicineReportKO',
  components: {
    UploadKoReport,
    ExpOther,
    CostCalc,
    AnalogForLog,
    ExpAbout,
    ExpAgency,
    ExpAssessment,
    LastActionDialog,
  },

  data: () => {
    return {
      data: null,
      loading: false,
    }
  },
  props: {
    initData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'plp_task_id',
      'versionListReady',
      'isManager',
      'storedPlpId',
    ]),
    aboutReady() {
      return !!this.data?.about
    },
    getAbout() {
      return this.aboutReady
        ? 'Информация о ЛП готова'
        : 'Информация о ЛП не готова!'
    },
    analogReady() {
      return !!this.data?.analog
    },
    getAnalog() {
      return this.analogReady ? 'Аналоги готово' : 'Аналоги не готово!'
    },
    costReady() {
      return !!this.data?.cost
    },
    getCost() {
      return this.costReady
        ? 'Расчет затрат готово'
        : 'Расчет затрат не готово!'
    },
    otherReady() {
      return !!this.data?.other
    },
    getOther() {
      return this.otherReady
        ? 'Прочие данные готово'
        : 'Прочие данные не готово!'
    },
    ageReady() {
      return !!this.data?.agencies
    },
    getAge() {
      return this.ageReady ? 'Агенства готово' : 'Агенства не готово!'
    },
    assessmentReady() {
      return !!this.data?.assessment
    },
    getAssessment() {
      return this.ageReady ? 'ЭП готово' : 'ЭП не готово!'
    },
    readyToReport() {
      return (
        !!this.data &&
        this.aboutReady &&
        this.analogReady &&
        this.costReady &&
        this.otherReady &&
        this.ageReady &&
        this.assessmentReady
      )
    },
    objAnalog() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'analog',
      }
    },
    objAge() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'agencies',
      }
    },
    objAssessment() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'assessment',
      }
    },
    objAbout() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'about',
      }
    },
    objCost() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'cost',
      }
    },
    objOther() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'other',
      }
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  async created() {
    this.loading = true
    try {
      const [report] = await Promise.all([
        this.GET_PLP_REPORT(SUB_EXPERTISE_FOR_KO_REPORT),
      ])

      this.data = report?.expertise
    } finally {
      this.loading = false
    }
  },
}
</script>
