<template>
  <v-container fluid>
    <v-container>
      <v-card elevation="0">
        <v-card-title>
          Готовность заключения по оценке методологического качества
        </v-card-title>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="list-item-two-line" />
        </v-card-text>
        <v-card-text v-else>
          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!aboutReady">
              <v-expansion-panel-header
                :style="!aboutReady ? 'cursor: default' : 'cursor: pointer'"
                :color="aboutReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="aboutReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !aboutReady"
                    :value="objAbout"
                  >
                    <template #content="{ value }">
                      <ExpAbout :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="aboutReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="aboutReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-1</v-icon
                  >
                  <span>{{ getAbout }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAbout :value="data.about" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!analogReady">
              <v-expansion-panel-header
                :style="!analogReady ? 'cursor: default' : 'cursor: pointer'"
                :color="analogReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="analogReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !analogReady"
                    :value="objAnalog"
                  >
                    <template #content="{ value }">
                      <AnalogForLog :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="analogReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="analogReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-2</v-icon
                  >
                  <span>{{ getAnalog }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <AnalogForLog :value="data.analog" readonly />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!keiReady">
              <v-expansion-panel-header
                :style="!keiReady ? 'cursor: default' : 'cursor: pointer'"
                :color="keiReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="keiReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !keiReady"
                    :value="objKei"
                  >
                    <template #content="{ value }">
                      <ExpertiseTabsList
                        :value="value"
                        readonly
                        log-view
                        dict-type="kei-mod"
                      />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="keiReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="keiReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-3</v-icon
                  >
                  <span>{{ getKei }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpertiseTabsList
                  v-if="keiReady"
                  :value="data['kei-mod']"
                  readonly
                  dict-type="kei-mod"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!avbReady">
              <v-expansion-panel-header
                :style="!avbReady ? 'cursor: default' : 'cursor: pointer'"
                :color="avbReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="avbReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !avbReady"
                    :value="objAvb"
                    dict-type="avb-mod"
                  >
                    <template #content="{ value }">
                      <ExpertiseTabsList :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="avbReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="avbReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-4</v-icon
                  >
                  <span>{{ getAvb }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpertiseTabsList
                  v-if="avbReady"
                  :value="data['avb-mod']"
                  readonly
                  dict-type="avb-mod"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!mainKeiReady">
              <v-expansion-panel-header
                :style="!mainKeiReady ? 'cursor: default' : 'cursor: pointer'"
                :color="mainKeiReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="mainKeiReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !mainKeiReady"
                    :value="mainObjKei"
                    dict-type="kei"
                  >
                    <template #content="{ value }">
                      <ExpertiseTabsList :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="mainKeiReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="mainKeiReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-5</v-icon
                  >
                  <span>{{ getMainKei }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpertiseTabsList
                  v-if="mainKeiReady"
                  :value="data['kei']"
                  readonly
                  dict-type="kei"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-2" :readonly="!mainAvbReady">
              <v-expansion-panel-header
                :style="!mainAvbReady ? 'cursor: default' : 'cursor: pointer'"
                :color="mainAvbReady ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="mainAvbReady ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !mainAvbReady"
                    :value="mainObjAvb"
                    dict-type="avb"
                  >
                    <template #content="{ value }">
                      <ExpertiseTabsList :value="value" readonly log-view />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="mainAvbReady ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text align-center"
                  ><v-icon
                    :style="mainAvbReady ? 'color: #5d9e52' : 'color: #ec9235'"
                    >mdi-numeric-6</v-icon
                  >
                  <span>{{ getMainAvb }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpertiseTabsList
                  v-if="mainAvbReady"
                  :value="data['avb']"
                  readonly
                  dict-type="avb"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <UploadMethodReport :data="data" :disable-upload="!readyToReport" />
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SUB_EXPERTISE_FOR_METHOD_REPORT } from '@/store/const/expertise'
import LastActionDialog from '@/components/viewLogs/LastActionDialog'
import ExpAbout from '@/components/expAbout/ExpAbout.vue'
import AnalogForLog from '@/components/expAnalogs/AnalogForLog.vue'
import ExpertiseTabsList from '@/components/expAvbKei/ExpertiseTabsList.vue'
import UploadMethodReport from '@/components/reportMethod/UploadMethodReport.vue'
export default {
  name: 'MedicineReportMethod',
  components: {
    UploadMethodReport,
    ExpertiseTabsList,
    AnalogForLog,
    ExpAbout,
    LastActionDialog,
  },

  data: () => {
    return {
      data: null,
      loading: false,
    }
  },
  props: {
    initData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'plp_task_id',
      'versionListReady',
      'isManager',
      'storedPlpId',
    ]),
    aboutReady() {
      return !!this.data?.about
    },
    getAbout() {
      return this.aboutReady
        ? 'Информация о ЛП готова'
        : 'Информация о ЛП не готова!'
    },
    analogReady() {
      return !!this.data?.analog
    },
    getAnalog() {
      return this.analogReady ? 'Аналоги готово' : 'Аналоги не готово!'
    },
    keiReady() {
      return !!this.data?.['kei-mod']?.ka
    },
    getKei() {
      return this.keiReady ? 'доработка КЭИ готово' : 'доработка КЭИ не готово!'
    },
    avbReady() {
      return !!this.data?.['avb-mod']?.ka
    },
    getAvb() {
      return this.avbReady ? 'доработка АВБ готово' : 'доработка АВБ не готово!'
    },
    mainKeiReady() {
      return !!this.data?.['kei']?.ka
    },
    getMainKei() {
      return this.mainKeiReady ? 'КЭИ готово' : 'КЭИ не готово!'
    },
    mainAvbReady() {
      return !!this.data?.['avb']?.ka
    },
    getMainAvb() {
      return this.mainAvbReady ? 'АВБ готово' : 'АВБ не готово!'
    },
    addKeiAvbReady() {
      return this.keiReady && this.avbReady
    },
    mainKeiAvbReady() {
      return this.mainKeiReady && this.mainAvbReady
    },
    readyToReport() {
      return (
        !!this.data &&
        this.aboutReady &&
        this.analogReady &&
        (this.addKeiAvbReady || this.mainKeiAvbReady)
      )
    },
    objAnalog() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'analog',
      }
    },
    objAbout() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'about',
      }
    },
    objKei() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'kei-mod',
      }
    },
    objAvb() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'avb-mod',
      }
    },
    mainObjKei() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'kei',
      }
    },
    mainObjAvb() {
      return {
        codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
        plp_id: this.storedPlpId,
        ex_type_name: 'avb',
      }
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  async created() {
    this.loading = true
    try {
      const [report] = await Promise.all([
        this.GET_PLP_REPORT(SUB_EXPERTISE_FOR_METHOD_REPORT),
      ])

      this.data = report?.expertise
    } finally {
      this.loading = false
    }
  },
}
</script>
