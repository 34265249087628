import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createParagraph,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'
import { capitalize, ru_ending } from '@/lib/strUtils'
import { OTHER_MEDICINE_EXP } from '@/components/expOther/const'

export const report_score_text_1 =
  'Необходимость применения лекарственного препарата для диагностики, профилактики, лечения или реабилитации заболеваний, синдромов или состояний, преобладающих в структуре заболеваемости и смертности граждан Российской Федерации, на основании данных государственного статистического наблюдения'
export const report_score_text_2 =
  'Наличие зарегистрированных в Российской Федерации воспроизведенных или биоаналоговых (биоподобных) лекарственных препаратов'
export const report_score_text_3 =
  'Наличие лекарственного препарата в перечне стратегически значимых лекарственных средств, производство которых должно быть обеспечено на территории Российской Федерации'
export const report_score_text_4 =
  'Наличие лекарственного препарата в перечнях лекарственных препаратов, финансируемых за счет средств бюджетов субъектов Российской Федерации'
export const report_score_text_5 =
  'Наличие производства (локализации) лекарственного препарата на территории Российской Федерации'

const report_score_index_1 = 0
const report_score_index_2 = 1
const report_score_index_3 = 2
const report_score_index_4 = 3
const report_score_index_5 = 4 // 4+ до упора

export function createCellsInRow(row) {
  return [
    createTableCell({
      children: [createParagraph(row.criteria)],
      width: {
        size: 80,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: [
        createParagraph(row.order, {
          alignment: AlignmentType.CENTER,
        }),
      ],
      width: {
        size: 20,
        type: WidthType.PERCENTAGE,
      },
    }),
  ]
}

export function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.criteria, {
          alignment: AlignmentType.CENTER,
        }),
      ],
      width: {
        size: 40,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.order, {
          alignment: AlignmentType.CENTER,
        }),
      ],
      width: {
        size: 60,
        type: WidthType.PERCENTAGE,
      },
    }),
  ]
}

/** результирующая оценка */

function createResultScoreRow(resultScore = 20) {
  return new TableRow({
    children: [
      createTableCell({
        borders: false,

        children: [
          createParagraph(
            `Итоговая оценка прочих данных по предложению:                                                                                                                                ${ru_ending(
              resultScore,
              ['балл', 'балла', 'баллов']
            )}`,
            {},
            { bold: true }
          ),
        ],
        columnSpan: 2,
      }),
    ],
  })
}

function createCriteria(criteria, text, withComment = false) {
  if (!criteria) return

  return new TableRow({
    children: [
      createTableCell({
        borders: false,
        width: {
          size: 85,
          type: WidthType.PERCENTAGE,
        },

        children: [
          createBoldParagraph(text, { alignment: AlignmentType.JUSTIFIED }),
          createParagraph(
            criteria.state && withComment
              ? `${capitalize(criteria.commentary)}. `
              : '',
            { alignment: AlignmentType.JUSTIFIED }
          ),
          createParagraph(
            criteria.state
              ? 'Да: ' + ru_ending(criteria.weight, ['балл', 'балла', 'баллов'])
              : 'Нет: 0 баллов'
          ),
        ],
      }),
      createTableCell({
        width: {
          size: 15,
          type: WidthType.PERCENTAGE,
        },
        borders: false,

        children: [
          createParagraph(criteria.state ? criteria.weight : '0', {
            alignment: AlignmentType.CENTER,
          }),
        ],
        verticalAlign: 'center',
      }),
    ],
  })
}

function createTableHeaderRow() {
  return new TableRow({
    children: createHeaderCellsInRow({
      criteria: 'Критерии оценки',
      order: 'Оценка (баллов)',
    }),
  })
}

function createTrueLocalization(tableData, strt_index) {
  const def = {
    commentary: '',
    state: false,
    text: '',
    weight: 0,
    id: 0,
  }
  return (
    tableData?.other?.find(({ state, id }) => id >= strt_index && state) || def
  )
}

export function createScoreTable(tableData) {
  for (let i = 0; i < tableData.other.length; i++) {
    tableData.other[i].commentary = OTHER_MEDICINE_EXP[i].commentary
  }
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        createCriteria(
          tableData.other[report_score_index_1],
          report_score_text_1
        ),
        createCriteria(
          tableData.other[report_score_index_2],
          report_score_text_2
        ),
        createCriteria(
          tableData.other[report_score_index_3],
          report_score_text_3
        ),
        createCriteria(
          tableData.other[report_score_index_4],
          report_score_text_4
        ),
        createCriteria(
          createTrueLocalization(tableData, report_score_index_5),
          report_score_text_5,
          true
        ),
        createResultScoreRow(tableData.sum),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createScoreTable] Ошибка построения таблицы оценок по экспертизам: ${err.message}`
    )
  }
}
