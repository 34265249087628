import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  createBoldParagraph,
  createParagraph,
  createSeparateParagraphs,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'

export function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [createParagraph(row.mnn ?? '-', {}, {}, 20)],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.lForma || '',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.AD[0]?.ru || 'Номер РУ отсутствует',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.AD[0]?.ruEndDate || '',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: createSeparateParagraphs(
          row.AD[0]?.obr || '0',
          { alignment: AlignmentType.CENTER },
          {},
          20
        ),
      }),
      createTableCell({
        children: [
          createParagraph(
            row.AD[0]?.govPack || '0',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.AD[0]?.govPrice || '0',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`${row?.mnn || '<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.mnn, { alignment: AlignmentType.CENTER }, 20),
      ],
      rowSpan: 2,
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.lf, { alignment: AlignmentType.CENTER }, 20),
      ],
      rowSpan: 2,
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.ru, { alignment: AlignmentType.CENTER }, 20),
      ],
      rowSpan: 2,
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.ruEndDate,
          { alignment: AlignmentType.CENTER },
          20
        ),
      ],
      rowSpan: 2,
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.obr, { alignment: AlignmentType.CENTER }, 20),
      ],
      rowSpan: 2,
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.packAndPrice,
          { alignment: AlignmentType.CENTER },
          20
        ),
      ],
      columnSpan: 2,
      width: {
        size: 20,
        type: WidthType.PERCENTAGE,
      },
    }),
  ]
}

function createHeaderCellsInSecondRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(
          row.containers,
          { alignment: AlignmentType.CENTER },
          20
        ),
      ],
      columnSpan: 1,
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.sum, { alignment: AlignmentType.CENTER }, 20),
      ],
      columnSpan: 1,
    }),
  ]
}

export function createTableHeaderRows() {
  return [
    new TableRow({
      children: createHeaderCellsInRow({
        mnn: 'Международное непатентованное наименование',
        lf: 'Лекарственная форма',
        ru: 'Номер регистрационного удостоверения (для включаемого или исключаемого лекарственного препарата) или количество регистрационных удостоверений для лекарственных препаратов, включенных в перечень жизненно необходимых и важнейших лекарственных препаратов',
        ruEndDate: 'Срок действия регистрационного удостоверения',
        obr: 'Обращение (упаковки) за период не менее календарного года, предшествующего подаче предложения',
        packAndPrice:
          'Объёмы государственных закупок за предшествующий календарный год',
      }),
    }),
    new TableRow({
      children: createHeaderCellsInSecondRow({
        containers: 'Упаковки',
        sum: 'Руб',
      }),
    }),
  ]
}

export function createConsequenceTable(tableData) {
  try {
    const mainRow = new TableRow({
      children: [...createCellsInRow(tableData[0])],
    })

    const analogRows = [
      new TableRow({
        children: [
          createTableCell({
            children: [
              createBoldParagraph(
                'Препараты с аналогичными показаниями в перечне жизненно необходимых и важнейших лекарственных препаратов ',
                { alignment: AlignmentType.CENTER },
                20
              ),
            ],

            columnSpan: 7,
          }),
        ],
      }),
      ...tableData.slice(1).map(
        row =>
          new TableRow({
            children: [...createCellsInRow(row)],
          })
      ),
    ]

    return new Table({
      alignment: AlignmentType.JUSTIFIED,
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        ...createTableHeaderRows(),
        mainRow,
        ...(analogRows.length > 1 ? analogRows : []),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createConsequenceTable] Ошибка построения таблицы доп. последствий: ${err.message}`
    )
  }
}
