import {
  DocxException,
  createParagraph,
  createParagraphTitle,
  createParagraphWithCenter,
  createContentParagraph,
  createCombinationParagraphWithSpace,
  createEmptyLine,
  BOLD_TEXT,
  UNDERLINED_TEXT,
  UNDERLINED_BOLD_TEXT,
  RED_LINE_INDENT,
} from '../docxFunctions'
import { AlignmentType } from 'docx'
import {
  MNN_PARSE,
  P_MNN1,
  P_MNN2,
  P_MNN3,
  P_MNN4,
} from '@/store/const/lpParse'

// ТИПЫ МНН
export const DEFAULT_NAME = MNN_PARSE[P_MNN1].name
export const GROUPING_NAME = MNN_PARSE[P_MNN2].name
export const CHEMICAL_NAME = MNN_PARSE[P_MNN3].name
export const TRADE_NAME = MNN_PARSE[P_MNN4].name

/**
 * ПЕРВАЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ до таблицы MnnTable
 */
export function createOneChapter(
  signerFIO,
  mnn,
  ath,
  form,
  reproducedLP,
  reproducedLPtext,
  isGNVLP,
  isVZN14,
  signerPosition,
  nameType
) {
  try {
    const options = { ...RED_LINE_INDENT }
    const createGNVLPParagraph = [
      isGNVLP
        ? createContentParagraph(
            'перечень жизненно необходимых и важнейших лекарственных препаратов для медицинского применения (далее - перечень жизненно необходимых и важнейших лекарственных препаратов);',
            options,
            { bold: true }
          )
        : createContentParagraph(
            'перечень жизненно необходимых и важнейших лекарственных препаратов для медицинского применения (далее - перечень жизненно необходимых и важнейших лекарственных препаратов);',
            options
          ),
    ]
    const createVZN14Paragraph = [
      isVZN14
        ? createContentParagraph(
            'перечень лекарственных препаратов, предназначенных для обеспечения лиц, больных гемофилией, муковисцидозом, гипофизарным нанизмом, болезнью Гоше, злокачественными  новообразованиями  лимфоидной, кроветворной и родственных им   тканей, рассеянным склерозом, гемолитико-уремическим синдромом, юношеским  артритом с системным началом, мукополисахаридозом I, II и VI типов, апластической   анемией неуточненной, наследственным дефицитом факторов II (фибриногена), VII (лабильного), X (Стюарта-Прауэра), лиц после трансплантации органов и (или) тканей  (далее - перечень дорогостоящих лекарственных препаратов).',
            options,
            { bold: true }
          )
        : createContentParagraph(
            'перечень лекарственных препаратов, предназначенных для обеспечения лиц, больных гемофилией, муковисцидозом, гипофизарным нанизмом, болезнью Гоше, злокачественными  новообразованиями  лимфоидной, кроветворной и родственных им   тканей, рассеянным склерозом, гемолитико-уремическим синдромом, юношеским  артритом с системным началом, мукополисахаридозом I, II и VI типов, апластической   анемией неуточненной, наследственным дефицитом факторов II (фибриногена), VII (лабильного), X (Стюарта-Прауэра), лиц после трансплантации органов и (или) тканей  (далее - перечень дорогостоящих лекарственных препаратов).',
            options
          ),
    ]
    return [
      createParagraph('В Комиссию Министерства здравоохранения', {
        alignment: AlignmentType.RIGHT,
      }),
      createParagraph('Российской Федераций', {
        alignment: AlignmentType.RIGHT,
        spacing: {
          after: 200,
        },
      }),
      createParagraphTitle('ЗАКЛЮЧЕНИЕ', { size: 24 }),
      createParagraphWithCenter(
        'по результатам оценки экономических последствий применения ' +
          'лекарственного препарата, изучения дополнительных последствий применения ' +
          'лекарственного препарата, а также оценки стоимости годового курса терапии',
        { size: 24 }
      ),
      createContentParagraph(
        '1. Настоящее заключение касается материалов, представленных:'
      ),
      createCombinationParagraphWithSpace([
        {
          type: BOLD_TEXT,
          content:
            'а) для включения лекарственного препарата в следующие перечни:',
        },
      ]),
      ...createGNVLPParagraph,
      ...createVZN14Paragraph,
      createEmptyLine(),
      createContentParagraph(
        '2. Информация об осуществляющих рассмотрение материалов сотрудниках федерального государственного бюджетного учреждения "Центр экспертизы и контроля   качества медицинской   помощи"   Министерства здравоохранения Российской Федерации (далее - Центр экспертизы) включает в себя:'
      ),
      createCombinationParagraphWithSpace([
        {
          content: 'а) Фамилия, имя, отчество, должность: ',
        },
        {
          type: UNDERLINED_TEXT,
          content: `${signerFIO}`,
        },
        {
          type: UNDERLINED_TEXT,
          content: `, ${signerPosition || 'не определена'};`,
        },
      ]),
      createCombinationParagraphWithSpace(
        [
          {
            content: 'б) Адрес Центра экспертизы: ',
          },
          {
            type: UNDERLINED_TEXT,
            content:
              '109028, г. Москва, Хохловский переулок, вл.10, стр.5, телефон (факс): +7 (495) 783-19-05, электронная почта: office@rosmedex.ru',
          },
        ],
        { alignment: AlignmentType.START }
      ),
      createEmptyLine(),
      createContentParagraph(
        '3. Информация о лекарственном препарате включает в себя:'
      ),
      createCombinationParagraphWithSpace([
        {
          content: 'а) наименование, в том числе:',
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content: 'международное непатентованное наименование: ',
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === DEFAULT_NAME ? mnn : ''}`,
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === DEFAULT_NAME ? ';' : ''}`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'в случае отсутствия международного непатентованного наименования - группировочное наименование: ',
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === GROUPING_NAME ? mnn : ''}`,
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === GROUPING_NAME ? ';' : ''}`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'в случае отсутствия международного непатентованного или группировочного наименований - химическое наименование: ',
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === CHEMICAL_NAME ? mnn : ''}`,
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === CHEMICAL_NAME ? ';' : ''}`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'в случае отсутствия указанных наименований - торговое наименование: ',
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === TRADE_NAME ? mnn : ''}`,
        },
        {
          type: BOLD_TEXT,
          content: `${nameType === TRADE_NAME ? ';' : ''}`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'б) код анатомо-терапевтическо-химической классификации лекарственного препарата: ',
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `${ath}`,
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `;`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'в) предлагаемые к включению или исключению лекарственные формы зарегистрированного лекарственного препарата (необходимо перечислить): ',
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `${form}`,
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `;`,
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          content:
            'г) информацию о воспроизведенных лекарственных препаратах (при наличии): ',
        },
      ]),
      createCombinationParagraphWithSpace([
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `${
            reproducedLP
              ? reproducedLPtext
              : 'нет воспроизведенных или биоаналоговых (биоподобных) лекарственных препаратов'
          }`,
        },
        {
          type: UNDERLINED_BOLD_TEXT,
          content: `;`,
        },
      ]),
    ]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
