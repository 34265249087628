var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Готовность заключения по оценке методологического качества ")]),(_vm.loading)?_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})],1):_c('v-card-text',[_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.aboutReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.aboutReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.aboutReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.aboutReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.aboutReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAbout},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpAbout',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1052276846)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.aboutReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.aboutReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-1")]),_c('span',[_vm._v(_vm._s(_vm.getAbout))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ExpAbout',{attrs:{"value":_vm.data.about,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.analogReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.analogReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.analogReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.analogReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.analogReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAnalog},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('AnalogForLog',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,2758321787)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.analogReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.analogReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-2")]),_c('span',[_vm._v(_vm._s(_vm.getAnalog))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('AnalogForLog',{attrs:{"value":_vm.data.analog,"readonly":""}})],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.keiReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.keiReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.keiReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.keiReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.keiReady)?_c('LastActionDialog',{attrs:{"value":_vm.objKei},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpertiseTabsList',{attrs:{"value":value,"readonly":"","log-view":"","dict-type":"kei-mod"}})]}}],null,false,2117000204)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.keiReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.keiReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-3")]),_c('span',[_vm._v(_vm._s(_vm.getKei))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.keiReady)?_c('ExpertiseTabsList',{attrs:{"value":_vm.data['kei-mod'],"readonly":"","dict-type":"kei-mod"}}):_vm._e()],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.avbReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.avbReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.avbReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.avbReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.avbReady)?_c('LastActionDialog',{attrs:{"value":_vm.objAvb,"dict-type":"avb-mod"},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpertiseTabsList',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1734001753)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.avbReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.avbReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-4")]),_c('span',[_vm._v(_vm._s(_vm.getAvb))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.avbReady)?_c('ExpertiseTabsList',{attrs:{"value":_vm.data['avb-mod'],"readonly":"","dict-type":"avb-mod"}}):_vm._e()],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.mainKeiReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.mainKeiReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.mainKeiReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.mainKeiReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.mainKeiReady)?_c('LastActionDialog',{attrs:{"value":_vm.mainObjKei,"dict-type":"kei"},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpertiseTabsList',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1734001753)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.mainKeiReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.mainKeiReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-5")]),_c('span',[_vm._v(_vm._s(_vm.getMainKei))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.mainKeiReady)?_c('ExpertiseTabsList',{attrs:{"value":_vm.data['kei'],"readonly":"","dict-type":"kei"}}):_vm._e()],1)],1),_c('v-expansion-panel',{staticClass:"mt-2",attrs:{"readonly":!_vm.mainAvbReady}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.mainAvbReady ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.mainAvbReady ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.mainAvbReady ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.mainAvbReady)?_c('LastActionDialog',{attrs:{"value":_vm.mainObjAvb,"dict-type":"avb"},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpertiseTabsList',{attrs:{"value":value,"readonly":"","log-view":""}})]}}],null,false,1734001753)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text align-center",style:(_vm.mainAvbReady ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{style:(_vm.mainAvbReady ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-6")]),_c('span',[_vm._v(_vm._s(_vm.getMainAvb))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.mainAvbReady)?_c('ExpertiseTabsList',{attrs:{"value":_vm.data['avb'],"readonly":"","dict-type":"avb"}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('UploadMethodReport',{attrs:{"data":_vm.data,"disable-upload":!_vm.readyToReport}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }