import { AlignmentType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createCombinationParagraphWithCenter,
  createParagraph,
  DEFAULT_TEXT,
  SUPERSCRIPT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
} from '../docxFunctions'

function createCellsInRow(row) {
  try {
    const trueKeys = row.link.map(v => String(v)) ?? []

    return [
      createTableCell({
        children: [
          createParagraph(row.ath, { alignment: AlignmentType.CENTER }, {}, 22),
        ],
      }),
      createTableCell({
        children: [
          createCombinationParagraphWithCenter([
            {
              type: DEFAULT_TEXT,
              content: row.mnn,
              size: 22,
            },
            {
              type: SUPERSCRIPT_TEXT,
              content: `${trueKeys}`,
            },
          ]),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.lForma,
            { alignment: AlignmentType.CENTER },
            {},
            22
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`${row?.mnn || '<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(row.ath, { alignment: AlignmentType.CENTER }, 22),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(row.mnn, { alignment: AlignmentType.CENTER }, 22),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(
          row.lForma,
          { alignment: AlignmentType.CENTER },
          22
        ),
      ],
    }),
  ]
}

function createTableHeaderRow() {
  const headers = {
    ath: 'АТХ код',
    mnn: 'МНН',
    lForma: 'Лекарственная форма',
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createMnnTable(tableData) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createMnnTable] Ошибка построения таблицы аналогов: ${err.message}`
    )
  }
}
