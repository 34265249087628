import { AlignmentType, ShadingType, Table, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  createParagraph,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
} from '../docxFunctions'
import { toMoney } from '@/lib/strUtils'

const regex = new RegExp(`^\\s*Предлагаемый*\\s*ЛП*`, 'gm')

function createCellsInRow(row) {
  try {
    const out = []
    let shading = null
    if (regex.test(row.groupName.value)) {
      shading = {
        type: ShadingType.SOLID,
        color: '87ceeb',
      }
    }
    // const trueKeys = row.link.map(v => String(v)) ?? []
    Object.keys(row).forEach(key => {
      if (row[key]?.mergeObj || !row[key]?.merged) {
        out.push(
          createTableCell({
            shading,
            children: [
              createParagraph(
                Number(row[key].value)
                  ? toMoney(row[key].value, true)
                  : row[key].value,
                { alignment: AlignmentType.CENTER },
                {},
                22
              ),
            ],
            columnSpan: row[key].mergeObj ? row[key].mergeObj.right + 1 : 1,
            rowSpan: row[key].mergeObj ? row[key].mergeObj.bottom + 1 : 1,
          })
        )
      }
    })

    return [...out]
  } catch (err) {
    throw new Error(`${row?.mnn || '<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(row) {
  const out = []
  Object.keys(row).forEach(key => {
    out.push(
      createTableCell({
        children: [
          createBoldParagraph(
            row[key],
            { alignment: AlignmentType.CENTER },
            22
          ),
        ],
      })
    )
  })

  return [...out]
}

function createTableHeaderRow(tableHeaders) {
  let headers
  if (tableHeaders[1].includes('комбинаци')) {
    headers = {
      groupName: tableHeaders[0] || '',
      combineName: tableHeaders[1] || '',
      mnn: tableHeaders[2] || '',
      patientPopulation: tableHeaders[3] || '',
      EI: tableHeaders[4] || '',
      requiredEiNumber: tableHeaders[5] || '',
      priceEiNumber: tableHeaders[6] || '',
      priceTherapy: tableHeaders[7] || '',
    }
  } else {
    headers = {
      groupName: tableHeaders[0] || '',
      mnn: tableHeaders[1] || '',
      patientPopulation: tableHeaders[2] || '',
      EI: tableHeaders[3] || '',
      requiredEiNumber: tableHeaders[4] || '',
      priceEiNumber: tableHeaders[5] || '',
      priceTherapy: tableHeaders[6] || '',
    }
  }
  return new TableRow({ children: createHeaderCellsInRow(headers) })
}

////////////////////////////////////////////
export function createGnvlpTable(tableData, tableHeaders) {
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(tableHeaders),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createCostTable] Ошибка построения таблицы расчёта затрат: ${err.message}`
    )
  }
}
