<template>
  <v-dialog
    :fullscreen="mobile"
    v-model="dialogState"
    max-width="1280"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="accent" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card :loading="loading" v-if="dialogStateKiller" class="overflow-hidden">
      <v-toolbar
        flat
        color="blue lighten-3"
        extension-height="0px"
        height="60px"
        dark
        class="dlg-header"
      >
        <v-icon class="mr-2">mdi-file-document-outline</v-icon>
        <v-toolbar-title class="font-weight-light"
          >Текущее состояние экспертизы</v-toolbar-title
        >
        <v-spacer />
        <v-btn color="white" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template #extension>
          <!-- полоса загрузки -->
          <v-progress-linear
            :active="loading"
            color="blue-grey lighten-4"
            style="top: -2px"
            height="4px"
            indeterminate
          />
        </template>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-skeleton-loader
          v-if="loading"
          type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
        />
        <slot v-else-if="lastLog" name="content" :value="lastLog"></slot>
        <v-alert v-else type="warning" dense text>
          <span
            >Отсутствуют данные по экспертизе (Возможно, экспертиза еще не была
            создана)</span
          >
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getFlag } from '@/components/expPricing/const'
import { Api } from '@/lib/axiosApi'
export default {
  name: 'LastActionDialog',
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    killTimer: 0,
    lastLog: null,
    loading: false,
  }),

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.value) {
      this.loadLastAction()
    }
  },
  watch: {
    dialogState(val) {
      if (val) {
        clearTimeout(this.killTimer)
        this.dialogStateKiller = false
        this.$nextTick(() => {
          this.dialogStateKiller = true
        })
      } else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 1000)
    },
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
  },
  methods: {
    getFlag,
    closeDialog() {
      this.dialogState = false
      this.$emit('onClose')
    },
    async loadLastAction() {
      this.loading = true
      this.errorText = ''
      this.lastLog = null
      try {
        const { data } = await Api.post(
          `actions-log/search?limit=1`,
          this.value
        )

        const id = data?.[0]?.id
        const code = data?.[0]?.code
        if (!id) throw new Error('empty data')

        const { data: log } = await Api.get(`actions-log/${id}`)

        code === 'PLP_SUBEX_VER_CREATED'
          ? (this.lastLog = log?.data?.data)
          : (this.lastLog = log?.data?.data?.data)
      } catch (error) {
        this.errorText = error.message
        console.log('it s logs search error')
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
  },
}
</script>

<style>
.dlg-header .v-toolbar__extension {
  padding: 0px 0px !important;
}
</style>
