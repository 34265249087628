import {
  DocxException,
  createContentParagraph,
  createEmptyLine,
  createParagraph,
  createParagraphWithCenter,
} from '../docxFunctions'
import { createSummaryTable } from '../allTables/createSummaryTable'
import { AlignmentType } from 'docx'

/**
 * ЧЕТВЁРТАЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ
 */
export function createFourChapter(comment) {
  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '6.  Сведения     для    подготовки     предложений    по     коррекции наименований   лекарственных   препаратов   и наименований   лекарственных форм лекарственных препаратов в соответствии  с  государственным  реестром лекарственных средств:'
      ),
      createEmptyLine(),
      createSummaryTable(),
      createEmptyLine(),
      createContentParagraph(
        '7.  Итоговая сумма оценки предложения и выводы экспертной комиссии о рекомендации или не рекомендации к включению лекарственного препарата в перечни лекарственных препаратов (рекомендованы могут быть только лекарственные препараты, набравшие по шкале оценки экономических последствий применения лекарственного препарата не менее 6 баллов (для орфанного лекарственного препарата – не менее 2 баллов):'
      ),
      createEmptyLine(),
      createContentParagraph(`Вывод: ${comment}`),
      createEmptyLine(),
      createContentParagraph(
        'Подписи и расшифровка подписей сотрудников центра экспертизы, осуществляющих оценку методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджет:'
      ),
      createEmptyLine(),
      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        '(Ф.И.О. сотрудника Центра экспертизы, подпись)'
      ),

      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        ' (руководитель Центра экспертизы, ФИО, подпись)'
      ),
      createParagraph('Дата__________', {
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
    ]
  } catch (err) {
    throw new DocxException(4, err.message)
  }
}
