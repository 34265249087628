import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  createBoldParagraph,
  createHyperLink,
  createParagraph,
  createSeparateParagraphs,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'
import {
  EX_AGENCIES_CADTH,
  EX_AGENCIES_IQWIG,
  EX_AGENCIES_NICE,
} from '@/components/expAgency/const'

function createAgenciesContent({ data }) {
  const outArr = []

  for (let i = 0; i < data.length; i++) {
    outArr.push(
      ...createSeparateParagraphs(data[i].translate || 'Не оценивался', {
        alignment: AlignmentType.JUSTIFIED,
      }),
      createParagraph(`${data[i].translate ? 'Источник:' : ''}`),
      createHyperLink(data[i].link || '', {
        spacing: {
          after: 200,
        },
      })
    )
  }
  return outArr
}

export function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: createSeparateParagraphs(row.condition),
        width: {
          size: 35,
          type: WidthType.PERCENTAGE,
        },
      }),
      createTableCell({
        width: {
          size: 65,
          type: WidthType.PERCENTAGE,
        },
        children: [
          createBoldParagraph(
            'Национальный институт здоровья и клинического совершенствования Великобритании (NICE):',
            { alignment: AlignmentType.JUSTIFIED }
          ),
          ...createAgenciesContent(row.agencies[EX_AGENCIES_NICE]),
          createBoldParagraph(
            'Институт качества и эффективности в здравоохранении Германии (IQWIG):',
            { alignment: AlignmentType.JUSTIFIED }
          ),
          ...createAgenciesContent(row.agencies[EX_AGENCIES_IQWIG]),
          createBoldParagraph(
            'Канадское агентство по лекарственным средствам и технологиям здравоохранения (CDA):',
            { alignment: AlignmentType.JUSTIFIED }
          ),
          ...createAgenciesContent(row.agencies[EX_AGENCIES_CADTH]),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`${row?.condition || '<не определёно>'}: ${err}`)
  }
}

export function createHeaderCellsInRow({ condition, agencies }) {
  return [
    createTableCell({
      children: [
        createBoldParagraph(condition, { alignment: AlignmentType.CENTER }),
      ],
    }),
    createTableCell({
      children: [
        createBoldParagraph(agencies, { alignment: AlignmentType.CENTER }),
      ],
    }),
  ]
}

export function createTableHeaderRow() {
  return new TableRow({
    children: createHeaderCellsInRow({
      condition: 'Показания к применению',
      agencies:
        'Рекомендации иностранных агентств по оценке медицинских технологий',
    }),
  })
}

export function createAgenciesTable({ condition }, { data: agencies }) {
  const tableData = [
    {
      condition,
      agencies,
    },
  ]

  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        new TableRow({
          children: [...createCellsInRow(...tableData)],
        }),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createAgenciesTable] Ошибка построения таблицы агенств: ${err.message}`
    )
  }
}
