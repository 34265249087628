import { createParagraphWithCenter, DocxException } from '../docxFunctions'
import { createRefTable } from '@/components/reportPresentation/allTables/createRefTable'

export function createTwoChapter(reference, mnn) {
  try {
    return [
      createParagraphWithCenter(
        `Сравнение предлагаемой к регистрации цены в РФ и`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createParagraphWithCenter(
        `зарегистрированной минимальной цены в референтных странах`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createRefTable(reference.pricing, mnn),
    ]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
